import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, combineLatest, map, pairwise, skip, Subscription} from 'rxjs';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { MessagesData } from 'src/app/shared/sl-data/messages';
import { DetailsModalService } from 'src/app/shared/sl-components/details-view/details-view.component';
import { RouteDataService, Route } from 'src/app/shared/sl-data/route';
import { JobDatService, Job } from 'src/app/shared/sl-data/job';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PlanningState } from 'src/app/planning/state';

interface Notification{
    job_id?: number;
    carrier_id?: string;
    message: string;
    author_type: 'system' | 'dispatcher' | 'client' | 'driver';
    author_name: string;
    sender_method: 'sms' | 'app';
    type: 'regular' | 'customer_confirmation' | 'schedule';
    date: string;
}



@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {

    public visible$ = new BehaviorSubject<boolean>(false);
    private filter$ = new BehaviorSubject<'' | 'client' | 'driver'>('');
    private audio = new Audio();
    private subscriptions: Subscription[] = [];

    constructor(
        private dateUtilsService: DateUtilsService,
        private messagesService: MessagesData,
        private detailsModal: DetailsModalService,
        private routeData: RouteDataService,
        private jobData: JobDatService, 
        private utilities: UtilitiesService,
        private state: PlanningState
    ) {
        
    }

    ngOnInit(): void {
        this.audio.src = "../../../../assets/sounds/elegant-notification-sound.mp3";
        this.audio.load();

        this.subscriptions.push(
            this.messagesService.notifications$.pipe(
                // Do not play a sound when default empty changes to the first real value
                skip(1),
                // Pair the previous and current states
                pairwise(),
    
                // Map the pair to detect additions and removals
                map(([previousNotifications, currentNotifications]) => {
                    const addedItems = currentNotifications.filter(cn => !previousNotifications.some(pn => pn.id === cn.id));
                    addedItems.length > 0 && this.audio.play();
                }),
            ).subscribe()
        );
    }

    ngOnDestroy(): void {
        for(let subscription of this.subscriptions){
            subscription.unsubscribe();
        }
    }

    public notificaions$ = combineLatest([this.messagesService.notifications$, this.filter$]).pipe(
        map(([ns, filter])=> 
            ns.filter(n=>filter==='' || filter === n.author_type)
            .map(n=> {return {...n, date: this.dateUtilsService.dateFormattedWithName(n.created_at).formated}}))
    );

    public totals$ = this.messagesService.notifications$.pipe(
        map(ns=>ns.reduce((totals, n)=>{
            const notifications = totals.notifications + 1;
            const customerNotifications = totals.customerNotifications + (n.author_type === 'client' ? 1 : 0);
            const driverNotifications = totals.driverNotifications + (n.author_type === 'driver' ? 1 : 0);
            return {notifications, customerNotifications, driverNotifications}
        }, { notifications:0, customerNotifications:0, driverNotifications:0}))
    );

    public bellClickHandler(){
        this.visible$.next(!this.visible$.value);
    }

    public notificationClickHandler(notificaion: Notification){
        if(notificaion.author_type==='driver' && notificaion.carrier_id){
            const route$ = new BehaviorSubject<Route>(null); 
            this.routeData.getLatestRouteForCarrier$(notificaion.carrier_id, this.state.currentDateStops$).subscribe(route$);
            this.detailsModal.show({
                view: 'route',
                route$: route$,
                routeTab: 'messages',
            });
        }
        if(notificaion.author_type==='client' && notificaion.job_id){
            const job$ = new BehaviorSubject<Job>(null); 
            this.jobData.getJob$(notificaion.job_id).subscribe(job$);
            this.detailsModal.show({ job$, view: 'stop', jobTab: 'messages' });
        }
    }

    public async cleanNotificationsHandler(){
        try{
            await this.messagesService.removeAllNotifications(this.filter$.value);
            this.utilities.notifySuccess('Notifications removed successfully.');
        }
        catch(err){
            if(err.details){
                this.utilities.notifyError(err.details);
                return;
            }
            this.utilities.notifyError('Unexpected error removing notifications. Please contact system admin');
        }
    }

    public async filter(from: 'client' | 'driver'){
        const filter = this.filter$.value === from ? '' : from;
        this.filter$.next(filter);
    }
}
