import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteDateSelectorComponent } from './route-date-selector.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SlComponentsModule } from 'src/app/shared/sl-components/sl-components.module';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    SlComponentsModule,
  ],
  declarations: [RouteDateSelectorComponent],
  exports: [RouteDateSelectorComponent]
})
export class RouteDateSelectorModule { }
