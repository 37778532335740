import { Injectable, Injector, InjectionToken, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';
import { ModalNComponent, ModalComponentProps, CONTAINER_DATA } from './modal.component';

@Injectable()
export class ModalServiceN {
    constructor(
        private overlay: Overlay,
        private _injector: Injector,
    ) { }

    private createInjector(dataToPass): PortalInjector {
        const injectorTokens = new WeakMap();
        injectorTokens.set(CONTAINER_DATA, dataToPass);
        return new PortalInjector(this._injector, injectorTokens);
    }

    private defaultOverlayConfig: OverlayConfig = {
        hasBackdrop: true,
        backdropClass: 'modal-backdrop',
        panelClass: 'modal-pane',
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        disposeOnNavigation: true,
    };

    public show<U, V>(component: ComponentType<U>, componentProps: V, config: OverlayConfig = {}) {
        const overlayRef = this.overlay.create({
            ...this.defaultOverlayConfig,
            ...config,
        });
        const modalPortal = new ComponentPortal(
            ModalNComponent,
            null,
            this.createInjector({
                component,
                componentProps,
                dismissModalFn: () => overlayRef.dispose(),
                setComponentInstance: (inst: ComponentRef<U>) => { },
            } as ModalComponentProps)
        );
        overlayRef.attach(modalPortal);

        overlayRef.backdropClick()
            .pipe(take(1))
            .subscribe(() => overlayRef.dispose());
    }
}
