<div class="header">
    Jobs with Syncing problems
</div>
<div class="body">

    <table width="100%" class="mat-elevation-z8">
        <tr>
            <th>Job Id</th>
            <th>Date</th>
            <th>Company</th>
            <th>Route</th>
            <th>Error</th>
        </tr>
        <tr class="data-row" *ngFor="let row of jobsListItems">
            <td><div [innerText]="row.id"></div></td>
            <td><div [innerText]="row.date"></div></td>
            <td><div [innerText]="row.companyId"></div></td>
            <td><div [innerText]="row.companyId"></div></td>
            <td><div [innerText]="row.erp_sync_error"></div></td>
        </tr>
    </table>

</div>
