export const environment = {
    production: true,
    firebase: {
        // apiKey: 'AIzaSyCUg1Im3F65H1bjnGnzxSqvPZR8uLVljuM',
        // authDomain: 'ne-delivery.firebaseapp.com',
        // databaseURL: 'https://ne-delivery.firebaseio.com',
        // projectId: 'ne-delivery',
        // storageBucket: 'ne-delivery.appspot.com',
        // messagingSenderId: '1095563290759'
        apiKey: 'AIzaSyAgITqpvaW6B65agsHsfEof4B5zWlcS7J0',
        authDomain: 'streamlion.firebaseapp.com',
        databaseURL: 'https://streamlion.firebaseio.com',
        projectId: 'streamlion',
        storageBucket: 'streamlion.appspot.com',
        messagingSenderId: '1089020865493',
        appId: '1:1089020865493:web:53bc7ac9edf3baf2ba6b5c',
        measurementId: 'G-2NWNJLRP98'

    },
    mapbox: {
        publicToken: 'pk.eyJ1Ijoic2FsbW9yZSIsImEiOiJjam54MTN6a3IwOThxM3dsNjlzdjd6ZHgyIn0.FirZLywH52kfG5ZjVw0TSg'
    }
};
