<app-card [header]="header">
    <div class="body">
        {{body}}
    </div>
    <div *ngIf="userInputLabel" class="userInputWrapper">
        <label>{{userInputLabel}}</label>
        <input class="userInput" ngModel #userInput>
    </div>
    <div class="buttons">
        <app-card-button *ngIf="okFn"
            color="#2ea054"
            (click)="cancelHandler()">
            Cancel</app-card-button>
        <app-card-button
            color="#EB718D"
            (click)="okHandler()">
            OK</app-card-button>
    </div>
</app-card>