    <app-icon name="bell" (click)="bellClickHandler()"></app-icon>
    <div class="count" *ngIf="(totals$ | async)?.notifications" [innerText]="(totals$ | async)?.notifications" ></div>
    
    
    <div class="messages" *ngIf="(visible$ | async) && (totals$ | async)?.notifications > 0">
        <div class="buttons">
            <div class="filters">
                <div class="filter-button"  [ngClass]="(filter$ | async) === 'driver' ? 'selected' : ''" (click)="filter('driver')">
                    <div class="filter">
                        <app-icon class="driver-filter" name="truck"></app-icon>
                        <div class="count" *ngIf="(totals$ | async)?.driverNotifications" [innerText]="(totals$ | async)?.driverNotifications" ></div>
                    </div>
                </div>
                <div class="filter-button" [ngClass]="(filter$ | async) === 'client' ? 'selected' : ''" (click)="filter('client')">
                    <div class="filter">
                        <app-icon class="person-filter" name="person"></app-icon>
                        <div class="count" *ngIf="(totals$ | async)?.customerNotifications" [innerText]="(totals$ | async)?.customerNotifications" ></div>
                    </div>
                </div>
            </div>
            <app-icon class="remove" name="recycle-bin" (click)="cleanNotificationsHandler()"></app-icon>
        </div>
        <div class="message" *ngFor="let notificaion of (notificaions$ | async)">
            <div>Pending message from {{notificaion.author_type}} <b>{{notificaion.author_name}}</b> on {{notificaion.date}}:</div>
            <div class="message-text">{{notificaion.message}}</div>
            <div *ngIf="notificaion.author_type==='client'" (click)="notificationClickHandler(notificaion)" class="goTo">Go to job</div>
            <div *ngIf="notificaion.author_type==='driver'" (click)="notificationClickHandler(notificaion)" class="goTo">Go to route</div>
        </div>
    </div>
