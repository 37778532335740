import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlServicesService } from './sl-services.service';
import { SlServicesPathsService } from './sl-services-paths.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
      SlServicesService,
      SlServicesPathsService,
  ]
})
export class SlServicesModule { }
