import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-card-button',
    templateUrl: './card-button.component.html',
    styleUrls: ['./card-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardButtonComponent {
    @HostBinding('style.background-color') get backgroundColor() {
        return this.color;
    }
    @HostBinding('class.disabled') get isDisabled() {
        return this.disabled;
    }
    @Input() private color = '#ABB0B0';
    @Input() private disabled = false;
    constructor() { }

}
