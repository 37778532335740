import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from 'firebase/auth';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanActivate {

    constructor(
        public angAuth: AngularFireAuth,
        private router: Router,
    ) { }

    public canActivate() {
        return this.authenticated$.pipe(map(auth => {
            if (!auth) {
                this.router.navigate(['login']);
            }
            return !!auth;
        }));
    }

    public get authenticated$() {
        return this.angAuth.user.pipe(map(Boolean));
    }

    public signInWithEmail = (email: string, password: string) => {
        return this.angAuth.signInWithEmailAndPassword(email, password)
    }

    public signOut() {
        return this.angAuth.signOut();
    }

    public resetPassword(email) {
        return this.angAuth.sendPasswordResetEmail(email);
    }

    public get user$(): Observable<User> {
        return this.angAuth.user;
    }
}
