import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CarrierData } from '../../sl-data/carrier';
import { Job } from '../../sl-data/job';
import { StopList } from '../../sl-data/location-types';
import { SlPlanningService } from '../../sl-planning/sl-planning.service';

@Component({
  selector: 'app-show-syncing-errors',
  templateUrl: './show-syncing-errors.component.html',
  styleUrls: ['./show-syncing-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowSyncingErrorsComponent implements OnInit {
  public jobsListItems = [];
  @Input() set syncingErrors(jobs: Job[]) {
    this.jobsListItems = jobs.map(job => {
      const dateValue = job.scheduling ? job.scheduling.itinerary.date : '';
      return {
        date: dateValue,
        id: job.id, companyId: job.companyId, erp_sync_error: this.replaceErrorText(job.erp_sync_error)
      }
    });
  }
  @Input() dismissFn: () => void;

  constructor() { }

  ngOnInit() {
  }

  replaceErrorText(text) {

    if (text.length < 150) {
      return text;
    }

    var rx = /Error:([^<]*)</g;
    var arr = rx.exec(text);
    return arr[0].substring(0, arr[0].length - 1).trim();
  }

}
