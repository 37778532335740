import { Injectable } from '@angular/core';
import { DataEnvService } from './data-env.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataPathsService {

    constructor(
        private dataEnv: DataEnvService,
    ) { }

    public global() {
        return `/global`;
    }

    public globalData() {
        return `${this.global()}/data`;
    }

    public comIds = 'com';

    public base$ = this.dataEnv.comId$.pipe(
        map(comId => `${this.comIds}/${comId}`)
    );

    public apiStates$ = this.base$.pipe(
        map(base => `${base}/app_states`)
    );

    public routes$ = this.base$.pipe(
        map(base => `${base}/routes`)
    );

    public routeZones$ = this.base$.pipe(
        map(base => `${base}/routeZones`)
    );

    public route$(id: string) {
        return this.routes$.pipe(
            map(base => `${base}/${id}`)
        );
    }

    public currentRouteForCarrier$(carrierId: string, date: string) {
        return this.base$.pipe(
            map(base => `${base}/carriers/${carrierId}/locations/${date}/`));
    }

    public jobs$ = this.base$.pipe(
        map(base => `${base}/jobs`)
    );

    public job$(id: number) {
        return this.jobs$.pipe(
            map(base => `${base}/${id}`)
        );
    }

    public itineraryCreationState$(date: string) {
        return this.apiStates$.pipe(
            map(base => `${base}/itinerary_creation_${date}`)
        );
    }

    public carriers$ = this.base$.pipe(
        map(base => `${base}/carriers`)
    );

    public carrierMessages$(carrierId: string) {
        return this.base$.pipe(
            map(base => `${base}/carriers/${carrierId}/chats/`));
    }

    public notifications$ = this.base$.pipe(
        map(base => `${base}/notifications/`)
    );

    public geo$(userId: string) {
        return this.base$.pipe(
            map(base => `${base}/users_history/${userId}/geo`)
        );
    }

}
