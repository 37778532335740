<app-card
    header="Time confirmation request results">
    <app-route-cardn
        [route]="(route$ | async)"
        [interactive]="false"
        [collapsed]="false"
        [showMessages]="false"
        ></app-route-cardn>
        <div class="stats-container">
            <div class="stat"
                *ngFor="let stat of (stats$ | async)">
                <app-icon
                    [name]="stat.icon"
                    [style.color]="stat.color"></app-icon>
                <div class="title">
                    {{stat.title}}
                </div>
                <div class="count">
                    {{stat.count}}
                </div>
            </div>
        </div>
        <div class="buttons">
            <app-card-button
                *ngFor="let but of (buttons$ | async)"
                [color]="but.color || '#ABB0B0'"
                (click)="but.handler()"
                [disabled]="but.disabled">
                <app-icon *ngIf="but.icon"
                    [name]="but.icon"></app-icon>
                {{but.label}}
            </app-card-button>
        </div>
        <div class="footer-text"
            *ngIf="footerText$ | async as text">
           {{text}}
        </div>
</app-card>