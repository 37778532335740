import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { ModalServiceN } from 'src/app/shared/modal/modal.service';
import { Job } from 'src/app/shared/sl-data/job';
import { SlPlanningService } from 'src/app/shared/sl-planning/sl-planning.service';
import { DatePickerModal } from '../../date-picker/date-picker.component';
import { CommunicationsData } from 'src/app/shared/sl-data/communications';
import { MessageDoc } from 'src/app/shared/sl-data/messages/messages-types';

export type JobDetailsTab = 'messages' | 'items' | 'Notes';

@Component({
    selector: 'app-stop-view',
    templateUrl: './stop-view.component.html',
    styleUrls: ['./stop-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StopViewComponent {
    public stop$ = new ReplaySubject<Job>(1);
    public selectedTab$ = new BehaviorSubject<JobDetailsTab>('items');
    public processing$ = new BehaviorSubject(false);
    public stops: Job;
    public displayedColumns: string[] = ['icon', 'sku', 'Quantity'];
    @Input() set stop(stop: Job) {
        this.stop$.next(stop);
    }
    @Input() set tab(tab: JobDetailsTab) {
        this.selectedTab$.next(tab);

    }
    constructor(
        private datePicker: DatePickerModal,
        private planning: SlPlanningService,
        private modalCtrl: ModalServiceN,
        private dateUtilsService: DateUtilsService,
        private communications: CommunicationsData,
    ) {
        this.stop$.subscribe((data) => {
            this.stops = data;
        });
        
        combineLatest([this.stop$,this.selectedTab$]).subscribe(([stp, selectedTab]) => {
            if(selectedTab === 'messages'){
                //async call, we want to display messages quickly
                this.markMessagesAsRead(stp?.messages).catch(err=> {console.log(err)});
            }
        });
        
    }

    public tabs$ = this.selectedTab$.pipe(
        map(selected => {
            return [
                'messages',
                'items',
                'Notes'
            ].map((tab: JobDetailsTab) => ({
                name: tab,
                cssClass: selected === tab ? 'selected' : '',
                clickHandler: () => {
                    if (tab == 'items') {
                        this.stop$.subscribe((items) => {
                            this.stops = items;
                        });
                    }
                    this.selectedTab$.next(tab)
                }
            }));
        })
    );

    public trackByFn(i, t) {
        return i;
    }

    public changeDateOpenPicker(jobDetailId: string) {
        this.datePicker.show({
            dateSelectedFn: (d) => this.applyDateChange(jobDetailId, this.dateUtilsService.momentToISO(d)),
            dates: [{ date: this.dateUtilsService.dateFromISO(this.stops.scheduling.itinerary.date), disabled: true }]
        });
    }

    public async applyDateChange(jobDetailId: string, newDate: string) {
        this.processing$.next(true);
        let result = await this.planning.changeJobItemDate(this.stops.routeId, jobDetailId, this.stops.id, newDate);
        if (result) {
            if (result.result !== "ok") {
                alert("There was an error trying to change the date: " + result.errors);
            }
        };
        this.processing$.next(false);
    }
    
    public formatDate(dateISO: string){
        return this.dateUtilsService.dateFormattedWithName(dateISO, 'M/D/YY').formated;
    }

    public formatCustomerName(){
        const firstName = this.stops?.customer?.firstName || '';
        const lastName = this.stops?.customer?.lastName || '';
        return `Client: ${firstName} ${lastName}`;
    }

    public addMessageCallback = async (message : string) => {
        const jobId = this.stops.id;
        const routeId = this.stops.routeId;
        this.communications.sendJobMessage(jobId, message);        
    }
    
    private markMessagesAsRead = async (messages: MessageDoc[] = []) => {
        //If no messages or all messages are read, they can be shown as is.
        if(!messages || !messages.some(m => m.from === 3 && m.isUnread)){
            return;
        }
        //Mark all customer messages as read for this job
        await this.communications.dispatcherSetChatMessagesAsReadForJob(this.stops.id);
    }

}
