import { Injectable } from '@angular/core';
import { DataService, CollectionQuery, WithDocId, DeepPartial, BatchTransaction } from '../data.service';
import { DataPathsService } from '../data-paths.service';
import { switchMap, map, tap } from 'rxjs/operators';
import { CustomerConfirmation, JobDoc } from './job-type';
import { toPromise } from '../util';
import { Timestamp } from 'firebase/firestore';
import { MessageDoc } from '../messages/messages-types';
import { SlServicesService } from '../../sl-services/sl-services.service';

@Injectable()
export class JobDatService {
    constructor(
        private dataCtrl: DataService,
        private paths: DataPathsService,
        private services: SlServicesService
    ) { }

    public getJob$ = (id: number) => {
        return this.paths.job$(id).pipe(
            switchMap(path => this.dataCtrl.getDoc$<JobDoc>(path)),
            map(job => new Job(job, this)),
        );
    }

    public getJobs$ = ({ date, route }: { date?: string; route?: string }) => {
        return this.paths.jobs$.pipe(
            switchMap(path => {
                return this.dataCtrl.getCollection$<JobDoc>({
                    path,
                    queries: [
                        (route !== undefined ? ['scheduling.itinerary.route_id', '==', route] : null),
                        (date ? ['scheduling.itinerary.date', '==', date] : null),
                    ].filter(Boolean) as CollectionQuery
                });
            }),
            map(rts => rts.map(jb => new Job(jb, this))),
        );
    }

    public getAllJobs$ = (date: string) => {
        return this.getJobs$({ date });
    }

    public getAllUnassignedJobs$ = (date: string) => {
        return this.getJobs$({ route: null, date });
    }

    public getAllAssignedJobs$ = (date) => {
        return this.getJobs$({ date })
            .pipe(
                map(jobs => jobs.filter(jb => !!jb.routeId)),
            );
    }

    public async updateJob(id, data: DeepPartial<JobDoc>) {
        const path = await toPromise(this.paths.job$(id));
        return this.dataCtrl.updateDoc<JobDoc>(path, data);
    }

    public async updateJobs(updates: { id; data: DeepPartial<JobDoc>; }[]) {
        const jobsPath = await toPromise(this.paths.jobs$);
        const changes: BatchTransaction<JobDoc>[] = updates
            .map(({ id, data }) => ({
                path: `${jobsPath}/${id}`,
                data,
            }));
        return this.dataCtrl.batchUpdateDocuments<JobDoc>(changes);
    }

    public async updateItemStatus(jobId: number, jobDetailId: number, status: string) {
        return await this.services.callService(`dispatcherSetItemStatus`, { jobId, jobDetailId, status });
    }
}

export class Job {
    constructor(
        private jobDoc: WithDocId<JobDoc>,
        private jobData: JobDatService
    ) { }

    public routeId = this.jobDoc.scheduling.itinerary.route_id;
    public id = this.jobDoc.id;
    public companyId = this.jobDoc.companyId;
    public keyPONum = this.jobDoc.keyPONum;
    public customer = this.jobDoc.customer;
    public items = this.jobDoc.items || [];
    public scheduling = this.jobDoc.scheduling;
    public status = this.jobDoc.status;
    public statusTime = this.jobDoc.statusTime;
    public address = this.jobDoc.address;
    public notes = this.jobDoc.notes;
    public erp_sync_status = this.jobDoc.erp_sync_status;
    public erp_sync_error = this.jobDoc.erp_sync_error;
    public erp_sync_attempts = this.jobDoc.erp_sync_attempts;
    public get notesArr() {
        return Object.values(this.jobDoc.notes || {});
    }
    public serviceTime = this.jobDoc.service_time;
    public _jobDoc = this.jobDoc;
    public stopNumber = this._jobDoc.scheduling.itinerary.stop_number;
    public arrivalEta = (this._jobDoc.scheduling.itinerary.arrival_eta || { toDate: () => new Date() }).toDate();
    public arrivalTimeWindowStart = this._jobDoc.scheduling.itinerary.arrivalTimeWindowStart?.toDate();
    public arrivalTimeWindowEnd = this._jobDoc.scheduling.itinerary.arrivalTimeWindowEnd?.toDate();
    public distanceFromPrev = this._jobDoc.scheduling.itinerary.distance_from_previous || 0;
    public durationFromPrev = this._jobDoc.scheduling.itinerary.duration_from_previous_traffic || 0;
    public durationFromPrevTraffic = this._jobDoc.scheduling.itinerary.duration_from_previous_traffic || 0;
    public messages? = this._jobDoc.messages;
    public photos? = this._jobDoc.photos;
    public orderId = this._jobDoc.orderId;
    public salesrep? = this._jobDoc.salesrep;
    public customerPOnum? = this._jobDoc.customerPOnum;
    public coordinated? = this._jobDoc.coordinated;
    public sequence? = this._jobDoc.sequence;
    public unresolvedMessages = this._jobDoc.unresolvedMessages;
    public startBefore = this._jobDoc.startBefore;
    public startAfter = this._jobDoc.startAfter;
    public thirdMan = this._jobDoc.thirdMan;
    public vip = this._jobDoc.vip;
    public revenue = this._jobDoc.revenue || 0;
    public orderJobMessages: {id: number, messages: MessageDoc[]} | null = null;
    public routeName?: string;
    public actualArrivalTime? = this._jobDoc.actualArrivalTime?.toDate();
    public actualEndTime? = this._jobDoc.statusId === 1 || this._jobDoc.statusId === 2 ? this._jobDoc.statusTime?.toDate() : undefined;

    public async updateServiceTime(time: number) {
        return this.jobData.updateJob(this.id, { service_time: time });
    }

    public async updateConfirmationStatus(customerConfirmation: CustomerConfirmation) {
        return this.jobData.updateJob(this.id, { scheduling: { customerConfirmation: customerConfirmation }});
    }

    public async updateDeliveryStatus(status: 'completed' | 'not_home' | '') {
        let statusId = 0;
        switch (status) {
            case 'completed':
                statusId = 1;     
                break;
            case 'not_home':
                statusId = 2;
                break;
        }
        return this.jobData.updateJob(this.id, { statusId });
    }

    public async updateItemStatus(jobDetailId: number, status: string) {
        return this.jobData.updateItemStatus(this.id, jobDetailId, status);
    }

    public updateCustomerPhone(phone: any) {
        this.jobData.updateJob(this.id, { customer: { phone: phone } });
    }

    public updateStopNumber(num: number) {
        this.jobData.updateJob(this.id, { scheduling: { itinerary: { stop_number: num } } });
    }

    public updateTimeWindow(arrivalTimeWindowStart: Date, arrivalTimeWindowEnd: Date) {
        this.jobData.updateJob(this.id, { scheduling: { itinerary: { arrivalTimeWindowStart: Timestamp.fromDate(arrivalTimeWindowStart), arrivalTimeWindowEnd: Timestamp.fromDate(arrivalTimeWindowEnd)} } });
    }

    public get isAssignedToRoute() {
        return !!this.routeId;
    }

    public get numberOfPhysicalItems(){
        return this.items.reduce((total, cIt) => { 
            const serviceMustBeCounted = ['DELIVERY','PICKUP'].includes(cIt.sku);
            const hasAnItem = cIt.subItems?.length===1 && cIt.subItems[0].type==='I';
            const quantity = hasAnItem && Number.isInteger(cIt.subItems[0].quantity) ? cIt.subItems[0].quantity : 1;
            const jobTotal = serviceMustBeCounted && hasAnItem ? quantity : 0;
            return total + jobTotal;
        }, 0);
    }

}
