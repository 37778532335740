import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { first } from 'rxjs/operators';
import { capitalizeFirstLetter } from '../util';
import { Job } from '../job';
import { DataEnvService } from '../data-env.service';
import { SlServicesService } from '../../sl-services/sl-services.service';

@Injectable({
    providedIn: 'root'
})
export class CommunicationsData {

    constructor(
        private dataCtrl: DataService,
        private dataEnv: DataEnvService,
        private services: SlServicesService
    ) { }

    public async sendJobMessage(jobId: number, content: string ) {
        const comId = await this.dataEnv.comId$.pipe(first()).toPromise();
        this.services.callService(`sendTwilioMessage/${comId}`, { message: content, jobId });
    }

    public jobToTwilioRequestParams(job: Job, comId) {
        let { firstName, lastName } = job.customer;
        firstName = capitalizeFirstLetter(firstName);
        lastName = capitalizeFirstLetter(lastName);

        return {
            com_id: comId,
            customer: { first_name: firstName!, last_name: lastName! },
            job_id: job.id
        }
    }

    public async dispatcherSetChatMessagesAsResolvedForJob(jobId: number) {
        return await this.services.callService(`dispatcherSetChatMessagesAsResolvedForJob`, { jobId });
    }

    public async dispatcherSetChatMessagesAsReadForJob(jobId: number) {
        return await this.services.callService(`dispatcherSetChatMessagesAsReadForJob`, { jobId });
    }

    public async dispatcherSetChatMessagesAsRead(carrierId: string, appDate: string) {
        return await this.services.callService(`dispatcherSetChatMessagesAsRead`, { carrierId, appDate });
    }

    public async dispatcherAddChatMessage(messageText: string, appDate: string, carrierId: string) {
        return await this.services.callService(`dispatcherAddChatMessage`, { messageText, appDate, carrierId });
    }


}
