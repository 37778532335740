import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { first, filter } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ModalServiceN } from '../../shared/modal/modal.service';
import { ResetPasswordComponent } from 'src/app/shared/sl-components/reset-password/reset-password.component';
import { DataEnvService } from 'src/app/shared/sl-data/data-env.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
    public email$ = new BehaviorSubject(null);
    public password$ = new BehaviorSubject(null);
    public loginStatus$ = new BehaviorSubject<'attempting' | 'loggedSuccess' | 'loggedFail'>(null);
    public loading$ = new BehaviorSubject(false);

    constructor(
        private auth: AuthService,
        private router: Router,
        private utilities:UtilitiesService,
        public dialog: MatDialog,
        public env: DataEnvService, 

    ) {
        this.auth.authenticated$.pipe(first(), filter(Boolean))
            .subscribe(() => this.navigateToDesiredPage());
            this.loginStatus$.subscribe((status)=>{
                if(status == 'loggedFail'){
                    this.utilities.notifyError("Email or password is incorrect");
                }else if(status == 'loggedSuccess'){
                    env.setComFromCurrentUser();
                    this.utilities.notifySuccess("Login Successful.");
                }
            })
    }

    public login() {
        this.loginStatus$.next('attempting');
        this.loading$.next(true);
        this.auth.signInWithEmail(this.email$.value, this.password$.value)
            .then(() => {
                this.loginStatus$.next('loggedSuccess');
                this.loading$.next(false);
                this.navigateToDesiredPage();
            })
            .catch(err => {
                this.loading$.next(false);
                this.loginStatus$.next('loggedFail')
            });
    }

    public forgotPassword() {
        const email = this.email$.value;
        this.auth.resetPassword(email)
            .then(() => window.alert('Email Sent'))
            .catch(() => window.alert('Password reset issue'));
    }

    public resetPassword(){      
       
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
    
        dialogConfig.data = {
            email: this.email$.value
        };
    
        const dialogRef = this.dialog.open(ResetPasswordComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => console.log("Dialog output:", data)
        );    
    }

    private navigateToDesiredPage() {
        this.router.navigate(['routing']);
    }   

}
