import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataPathsService } from './data-paths.service';
import { DataEnvService } from './data-env.service';
import { DataService } from './data.service';
import { JobDatService } from './job';
import { RouteDataService } from './route';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  providers: [DataService, DataPathsService, JobDatService, RouteDataService]
})
export class SlDataModule { }
