import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export function toPromise<T>(ob: Observable<T>) {
    return ob.pipe(first()).toPromise();
}

export function compareObjectArrays(arr1: any[], arr2: any[]): boolean {
    const objectsEqual = (o1, o2) => 
    typeof o1 === 'object' && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
            && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
    const result = arr1.length === arr2.length && arr1.every((o, idx) => objectsEqual(o, arr2[idx]));
    return result;
}

