import { Injectable } from '@angular/core';
import { DataService, WithDocId } from '../data.service';
import { DataPathsService } from '../data-paths.service';
import { switchMap, map, shareReplay, first } from 'rxjs/operators';
import { RouteDoc, RouteZoneDoc } from './route-type';
import { DeepPartial } from '../data.service';
import { toPromise } from '../util';
import { BehaviorSubject, Observable } from 'rxjs';
import { Job, JobDatService } from '../job';
import { filter, merge } from 'lodash';
import { GeoLocation, StopList } from '../location-types';
import { SlPlanningService } from '../../sl-planning/sl-planning.service';
const polyline = require('@mapbox/polyline');

@Injectable()
export class RouteDataService {

    constructor(
        private dataCtrl: DataService,
        private paths: DataPathsService,
        private jobData: JobDatService,
        private planningService: SlPlanningService,
    ) { }

    public getRoute$ = (id: string, currentDateStops$: Observable<Job[]>) => {
        return this.paths.route$(id).pipe(
            switchMap(path => this.dataCtrl.getDoc$<RouteDoc>(path)),
            map(rt => new Route(rt, this.jobData, this, this.planningService, currentDateStops$)),
        );
    }

    public getRoutes$ = (date: string = null, currentDateStops$: Observable<Job[]>) => {
        return this.paths.routes$.pipe(
            switchMap(path => {
                return this.dataCtrl.getCollection$<RouteDoc>({
                    path,
                    queries: [['date', '==', date]],
                    orderBy: 'carrierName'
                });

            }),
            map(rts => rts.map((rt, i) => new Route(rt, this.jobData, this, this.planningService, currentDateStops$, i))),
        );
    }

    public getRouteZones$ = () => {
        return this.paths.routeZones$.pipe(
            switchMap(path => {
                return this.dataCtrl.getCollection$<RouteZoneDoc>({
                    path
                });

            }),
            map(rzs => rzs.map((rz) => {
                const {coordinatesPolyline, docId, ...rest} = rz;
                const coordinates = polyline.decode(rz.coordinatesPolyline);
                
                return {...rest, coordinates, id: docId};
            })),
        );
    }

    public async updateRoute(id, data: DeepPartial<RouteDoc>) {
        const path = await toPromise(this.paths.route$(id));
        return this.dataCtrl.updateDoc<RouteDoc>(path, data);
    }

    public getCurrentRouteForCarrier$(route: Route) {
        return this.paths.currentRouteForCarrier$(route.carrierId, route.date).pipe(
            switchMap(path => {
                return this.dataCtrl.getDoc$<any>(path)
            }),
            map(currentRoute => {
                return currentRoute;
            }),
        );
    }

    public getLatestRouteForCarrier$(carrierId, currentDateStops$: Observable<Job[]>) {
        return this.paths.routes$.pipe(
            switchMap(path => {
                return this.dataCtrl.getCollection$<RouteDoc>({
                    path,
                    orderBy: "date", orderDirection: "desc",
                    queries: [['carrierId', '==', carrierId]],
                    limit: 1
                });

            }),
            map(rts => rts.length === 1 ? new Route(rts[0], this.jobData, this, this.planningService, currentDateStops$) : null)
        );
    }
}

export class Route {
    cubic_feet: any;
    constructor(
        private routeDoc: WithDocId<RouteDoc>,
        private jobData: JobDatService,
        private routeData: RouteDataService,
        private planningService: SlPlanningService,
        private currentDateStops$: Observable<Job[]>,
        private index = null,
    ) { }

    public coords = this.routeDoc.coordinatesPolyline ? polyline.decode(this.routeDoc.coordinatesPolyline).map(c=>{return {lat:c[1], lng:c[0]}}) : (this.routeDoc.routeLegs || []).map(leg => leg.coordinates).flat();
    public id = this.routeDoc.docId;
    public carrierName = this.routeDoc.carrierName;
    public date = this.routeDoc.date;
    public carrierId = this.routeDoc.carrierId;
    public virtualCarrier: boolean = this.routeDoc.virtualCarrier ?? false;
    public lastVirtualCarrierId: string | null = this.routeDoc.lastVirtualCarrierId;
    public lastVirtualCarrierName: string | null = this.routeDoc.lastVirtualCarrierName;
    public color = stringToColor(this.id, this.index);
    public startTime = this.routeDoc.startTime ? this.routeDoc.startTime.toDate() : null;
    public endTime = this.routeDoc.endTime ? this.routeDoc.endTime.toDate() : null;
    public totalDuration = this.routeDoc.totalDuration || 0;
    public totalDurationTraffic = this.routeDoc.totalDurationTraffic;
    public totalDistance = this.routeDoc.totalDistance || 0;
    public routeLegs = this.routeDoc.routeLegs;
    public startPoint = this.routeDoc.startPoint;
    public endPoint = this.routeDoc.endPoint;
    public locked = this.routeDoc.locked;
    public status = this.routeDoc.status;
    public itineraries = this.routeDoc.itineraries;
    public currentLocation = this.routeDoc.currentLocation;
    public itineraryNameApplied = this.routeDoc.itineraryNameApplied;
    public driverStatuses = this.routeDoc.driver_status;
    public stops$ = this.currentDateStops$.pipe(
        map(stops => {
            return stops.filter(s=>s.routeId===this.id).sort((x, y) => x.stopNumber - y.stopNumber)
        }),
        shareReplay()
    );

    public setRouteLock(locked: boolean) {
        return this.planningService.updateRouteLock(this.id, locked);
    }
    public updateInProg = this.routeDoc.routing_in_progress;
    public optimizationInProg = this.routeDoc.optimization_in_progress;

    //no need to put it on the document as it could be processed in parallel, the lock is mostly to avoid confusing the user
    public printingInProg = false;
}

const usedColors = new BehaviorSubject<number[]>([]);

function stringToColor(str: string, index?: number) {
    let strAsNumber;
    if (typeof index === 'number') {
        strAsNumber = index % 6;
    } else {
        const randomTo6 = Math.ceil(Math.random() * 6);
        const nextInSeqTo6 = usedColors.getValue().length % 6;
        strAsNumber = Math.round((randomTo6 + nextInSeqTo6) / 2)
        usedColors.next([...usedColors.getValue(), strAsNumber]);
    }

    const pallet = [
        ['#F27E6A', '#EC9747', '#EE7755', '#FC824D', '#DC5349'],
        ['#8D73CE', '#B175B4', '#AB4795', '#9F60BF', '#A57AE5'],
        ['#D58094', '#E95EBC', '#F56374', '#F55F9F', '#F2577F'],
        ['#668FC4', '#6D93E2', '#4C829F', '#537EB1', '#459DC1'],
        ['#57C688', '#00AA83', '#87A254', '#07C198', '#71A762'],
        ['#C68257', '#A35D5B', '#8A4B56', '#C47C69', '#A18C7C'],
        ['#00BEBE', '#21A6B8', '#20B7A7', '#5DAEAD', '#15B0AB'],
    ];

    const colorTypeIndex = strAsNumber;
    const colorShadeIndex = Math.min((new Date()).getDay()+11, 4);
    //const colorShadeIndex = index % 4;
    const colorGroup = pallet[colorTypeIndex];
    //const colorShade1 = colorGroup[colorShadeIndex];
    const patt1 = /[0-9,A-F]/g;
    const result = str.match(patt1) + colorGroup.join('').replace('#', '');
    const colorShade = getRandomColor(index, colorShadeIndex);
    return colorShade;
}

function getRandomColor(index, colorShadeIndex) {
    var letters = 'F27E6AEC9747EE7755FC824DDC53498D73CEB175B4AB47959F60BFA57AE5D58094E95EBCF56374F55F9FF2577F668FC46D93E24C829F537EB1459DC157C68800AA8387A25407C19871A762C68257A35D5B8A4B56C47C69A18C7C00BEBE21A6B820B7A75DAEAD15B0AB0123456789ABCDEFFEDCBA9876543210F27E6AEC9747EE7755FC824DDC53498D73CEB175B4AB4795';
    var color = '#';
    letters = reverseString(letters);
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor((i * index) + (index % 6) + (i % colorShadeIndex) + i)];
    }
    return color;
}

function reverseString(str) {
    var splitString = str.split("");
    var reverseArray = splitString.reverse();
    var joinArray = reverseArray.join("");
    return joinArray;
}