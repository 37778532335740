<div class="header">
    <div class="tabs">
        <div class="tab"
            *ngFor="let tab of tabs$ | async; trackBy: trackByFn"
            [ngClass]="tab.cssClass" [innerText]="tab.name | uppercase" (click)="tab.clickHandler()"></div>
    </div>
    <div class="stop-info"></div>
</div>

<ng-container [ngSwitch]="(selectedTab$ | async)">
    <app-chat-box *ngSwitchCase="'messages'"
        [messages]="(stop$ | async)?.messages" [userName]="formatCustomerName()" otherRecipient="DISPATCHER" [addMessageCallback]="addMessageCallback"></app-chat-box>
    <app-notes *ngSwitchCase="'Notes'"
        [job]="(stop$ | async)"></app-notes>

    <div class="items-row" *ngSwitchCase="'items'">
        <div class="items-col" *ngIf="(processing$ | async)" >
            <mat-progress-spinner strokeWidth="5" diameter="50" mode="indeterminate"></mat-progress-spinner>
        </div>
        
        <ng-container *ngIf="!(processing$ | async)">
            <table width="100%" class="mat-elevation-z8">
                <tr>
                    <th>&nbsp;</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>DMI</th>
                    <th>Date</th>
                    <th>&nbsp;</th>
                </tr>

                <tr class="data-row" *ngFor="let row of stops.items">
                    <td><app-icon [name]="row.category" type="product"></app-icon></td>
                    <td><div class="centered" *ngIf="row.sku" [innerText]="row.sku"></div></td>
                    <td><div class="centered" [innerText]="row.quantity"></div></td>
                    <td><div [innerText]="row.description"></div></td>
                    <td><div class="centered" [innerText]="row.category"></div></td>
                    <td><div class="centered" *ngIf="stops.keyPONum" [innerText]="stops.keyPONum"></div></td>
                    <td><div [innerText]="formatDate(stops.scheduling.itinerary.date)" class="nowrap"></div></td>
                    <td><app-icon style="margin-top: 0px;" name="edit-date" type="general" (click)="changeDateOpenPicker(row.jobDetailId)"></app-icon></td>
                </tr>
            </table>

            <!--div class="items-col" *ngFor="let row of stops.items">
                <div class="list">
                    <div class="left-col">
                        <app-icon [name]="row.category" type="product"></app-icon>                
                        <div class="content">
                            <div class="subtitle" *ngIf="row.sku" [innerText]="row.sku"></div>
                            <div class="title" [innerText]="row.description"></div>
                            <div class="subtitle" [innerText]="row.category"></div>
                            <div class="subtitle" *ngIf="stops.keyPONum" [innerText]="'DMI:' + stops.keyPONum"></div>
                            <div class="subtitle-date" [innerText]="'Date:' + stops.scheduling.itinerary.date"></div>
                            <button mat-raised-button color="primary" class="change-date-button" (click)="changeDateOpenPicker(row.jobDetailId)">Change Date</button>
                        </div>
                    </div>
                    <div class="amount" [innerText]="row.quantity"></div>
                </div>
            </div-->
        </ng-container>
    </div>
</ng-container>