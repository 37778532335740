import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ViewType = 'map' | 'assignments' | 'messages';

@Injectable({
    providedIn: 'root'
})
export class MainNavStateService {
    public views: ViewType[] = [
        'map',
        'assignments',
        'messages'
    ];

    public selectedView$ = new BehaviorSubject<ViewType>('map');
    public updating$ = new BehaviorSubject<boolean>(false);
    public updatingMessage$ = new BehaviorSubject<string>('Calculating the best routes. This process may take up to 1 minute.');
    public updatingCustomAnimation$ = new BehaviorSubject<string|null>(null);

    public setSelectedView(type: ViewType) {
        this.selectedView$.next(type);
    }
    public setUpdating(updating: boolean, message: string = '', customAnimation: string|null = null){
        this.updatingCustomAnimation$.next(customAnimation ? `/assets/img/${customAnimation}` : customAnimation);
        this.updatingMessage$.next(updating ? message : '');
        this.updating$.next(updating);
    }
    constructor() { }
}
