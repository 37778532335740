import { Component, ChangeDetectionStrategy, Input, HostBinding, ElementRef } from '@angular/core';
import { BehaviorSubject, of, merge, timer } from 'rxjs';
import { pairwise, switchMap, delay, mapTo } from 'rxjs/operators';

type PositionY = 'left' | 'right';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppTooltipComponent {
    constructor(
        public elRef: ElementRef,
    ) { }
    @Input() set text(text: string) {
        this.text$.next(text);
    }
    @Input() set color(color: string) {
        this.color$.next(color);
    }
    @Input() set show(show: boolean) {
        this.show$.next(show);
    }
    @Input() positionY: PositionY = 'right';
    @Input() offsetY = 0;
    @Input() offsetX = -5;
    @Input() delay = 250;
    public text$ = new BehaviorSubject<string>('');
    public color$ = new BehaviorSubject<string>('#2f3035');
    public show$ = new BehaviorSubject<boolean>(false);
    @HostBinding('style.transform') get rotationStyles() {
        return this.positionY === 'left' ? 'rotateY(180deg)' : '';
    }
    @HostBinding('style.left') get leftStyles() {
        return this.offsetY ? `${this.offsetY}px` : '50%';
    }
    @HostBinding('style.top') get topStyles() {
        return `${this.offsetX}px`;
    }

    public cssClasses$ = (
        merge(
            of(null),
            this.show$.pipe(
                switchMap(show => {
                    if (show) {
                        return timer(this.delay).pipe(mapTo(true));
                    }
                    return of(false);
                })
            ),
        ).pipe(
            pairwise(),
            switchMap(([prev, cur]) => {
                if (cur === true) {
                    return of('show');
                }
                if (cur === false && prev === true) {
                    return merge(
                        of('hide').pipe(delay(350)),
                        of(['hide', 'animate'])
                    );
                }
                return of('hide');
            }),
        )
    );

}
