import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { first, groupBy, mergeMap, debounceTime, } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DataEnvService } from '../../shared/sl-data/data-env.service';
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-jobs-grid',
  templateUrl: './jobs-grid.component.html',
  styleUrls: ['./jobs-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsGridComponent implements OnInit {
  jobsSource: any = {};
  dataSource: any = {};
  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  dataIsChanging: boolean = false;

  constructor(
    private fns: AngularFireFunctions,
    private dataEnv: DataEnvService,
  ) {
    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== "";
    }

    const currentInstance = this;

    this.jobsSource = new CustomStore({
      key: "docId",
      load: async function (loadOptions: any) {

        //const skip = loadOptions.skip ? loadOptions.skip : 0;
        //const take = loadOptions.take ? loadOptions.take : 0;
        const dateStart = currentInstance.selectedStartDate.toISOString().split('T')[0];
        const dateEnd = currentInstance.selectedStartDate.toISOString().split('T')[0];

        const comId = await dataEnv.comId$.pipe(first()).toPromise();
        //const comId = "test";
        const callable = fns.httpsCallable(`queryJobsList/${comId}/${dateStart}/${dateEnd}/1/1`);
        return callable({}).toPromise().then((data: any) => {

          data.data.forEach(job => {
            job.scheduledDate = job.scheduling.itinerary.date;
            job.itemsCount = job.items.length;
            job.customerName = job.customer.firstName + ' ' + job.customer.lastName;
          });

          return {
            data: data.data,

          };
        });
      }
    });

    this.dataSource = new DataSource({
      store: this.jobsSource
    });

  }

  public yesterdayClickHandler() {
    this.dataIsChanging = true;
    const newDate = moment().add(-1, 'days').toDate();
    this.selectedStartDate = newDate;
    this.selectedEndDate = newDate;
    this.dataIsChanging = false;
    this.dataSource.reload();
  }

  public todayClickHandler() {
    this.dataIsChanging = true;
    const newDate = moment().toDate();
    this.selectedStartDate = newDate;
    this.selectedEndDate = newDate;
    this.dataIsChanging = false;
    this.dataSource.reload();
  }

  public tomorrowClickHandler() {
    this.dataIsChanging = true;
    const newDate = moment().add(1, 'days').toDate();
    this.selectedStartDate = newDate;
    this.selectedEndDate = newDate;
    this.dataIsChanging = false;
    this.dataSource.reload();
  }

  public dateChangedStart(e) {
    if (this.dataIsChanging)
      return;
    this.selectedStartDate = e.value;
    this.dataSource.reload();
  }

  public dateChangedEnd(e) {
    if (this.dataIsChanging)
      return;
    this.selectedEndDate = e.value;
    this.dataSource.reload();
  }

  ngOnInit() {
  }

}

/*
Possible filters:
Date
Status



*/





