<div class="wrapper"
    *ngIf="component$ | async as comp"
    [appTooltip]="(tooltip$ | async)?.text"
    [color]="(tooltip$ | async)?.color"
    [ngStyle]="{
        'color': comp.color,
        'border-color': comp.color,
        'border-style': comp.border ? 'solid' : 'none'
    }"
    [ngClass]="comp.border ? 'with-border' : ''"
    (click)="clickHandler($event)">
    <app-icon
        *ngIf="comp.icon as icon"
        [name]="icon"
        [style.width]="comp.iconWidth"
        ></app-icon>
    <div class="text"
        *ngIf="comp.text as text"
        [innerText]="text"></div>
</div>