import { Component, OnInit, ChangeDetectionStrategy, Injectable, Input, ViewChild } from '@angular/core';
import { ModalServiceN } from '../../modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import moment, { Moment } from 'moment';
import { MatCalendar } from '@angular/material/datepicker';
import { map } from 'rxjs/operators';

interface CalDate {
    date: Date;
    disabled?: boolean;
}
export interface DatePickerProps {
    dateSelectedFn: (d: Moment) => void;
    dates?: CalDate[];
}

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent {
    @ViewChild('matCal', { static: true }) private cal: MatCalendar<Moment>;
    @Input() dismissFn: () => void;
    @Input() dateSelectedFn: DatePickerProps['dateSelectedFn'];
    @Input() dates: CalDate[] = [];
    public activeDate$ = new BehaviorSubject(moment());
    public headerYear$ = this.activeDate$.pipe(
        map(date => date.format('YYYY'))
    );
    public headerDate$ = this.activeDate$.pipe(
        map(date => date.format('ddd, MMM DD'))
    );

    constructor() {}

    public dateChanged(ev: Moment) {
        this.activeDate$.next(ev);
        this.cal.selected = ev;
    }

    public dateClass = (ev: Moment) => {
        if (this.dates.some(({ date, disabled }) =>  ev.isSame(date, 'day') && disabled)) {
            return 'disabled';
        }
    }

    public matCalDateFilter = (ev: Moment) => {
        if (this.dates.some(({ date, disabled }) =>  ev.isSame(date, 'day') && disabled)) {
            return false;
        }
        return true;
    }

    public cancelHandler() {
        this.dismissFn();
    }

    public okayHandler() {
        this.dateSelectedFn(this.activeDate$.value);
        this.dismissFn();
    }
}

@Injectable()
export class DatePickerModal {
    constructor(
        private modal: ModalServiceN,
    ) { }

    public show(props?: DatePickerProps) {
        this.modal.show(DatePickerComponent, props || {});
    }
}
