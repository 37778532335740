import {
    Component, OnInit, ChangeDetectionStrategy, Input,
    ViewChild, ViewContainerRef, ComponentRef, AfterViewInit, AfterViewChecked,
    Type, ComponentFactoryResolver, Inject, InjectionToken
} from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';


export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

export interface ModalComponentProps {
    component: ComponentType<any>;
    componentProps?: any;
    dismissModalFn: () => void;
    setComponentInstance: (inst: ComponentRef<any>) => void;
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalNComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('contentSlot', { read: ViewContainerRef, static: false }) content: ViewContainerRef;

    constructor(
        @Inject(ComponentFactoryResolver) private resolver: ComponentFactoryResolver,
        @Inject(CONTAINER_DATA) public props: ModalComponentProps,
    ) { }

    ngAfterViewInit() {
        const factory = this.resolver.resolveComponentFactory(this.props.component);
        const componentRef = this.content.createComponent(factory);
        const componentInstance = componentRef.instance;

        Object.keys(this.props.componentProps).forEach(key => {
            (componentInstance as any)[key] = this.props.componentProps[key];
        });
        (componentInstance as any).dismissFn = this.props.dismissModalFn;

        this.props.setComponentInstance(componentInstance);

        this.content.get(0).detectChanges();
    }

    ngAfterViewChecked() {
        if (this.content.get(0)) {
            this.content.get(0).detectChanges();
        }
    }

}
