import { Injectable } from '@angular/core';
import { ModalServiceN } from '../../modal/modal.service';
import { TimeConfirmationCompProps, TimeConfirmationRequestComponent } from './time-confirmation-request.component';

@Injectable()
export class TimeConfirmationRequestModal {

  constructor(
      private modal: ModalServiceN,
  ) { }

  public show(props: TimeConfirmationCompProps) {
      this.modal.show(
          TimeConfirmationRequestComponent,
          props
      );
  }
}
