import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlCustomerConfirmationService } from './sl-customer-confirmation.service';
import { SlServicesModule } from '../sl-services/sl-services.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';



@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SlServicesModule,
        MatCardModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        SlCustomerConfirmationService,
    ]
})
export class SlCustomerConfirmationModule { }
