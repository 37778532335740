<div class="wrapper"
    [ngClass]="cssClasses$ | async"
    [ngStyle]="{
        'background-color': color$ | async,
        transform: positionY === 'left' ? 'rotateY(180deg)' : ''
    }">
    <div class="text"
        [innerText]="text$ | async"
        [style.transform]="positionY === 'left' ? 'rotateY(180deg)' : ''"></div>
</div>
