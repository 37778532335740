import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ModalServiceN } from './modal.service';
import { ModalNComponent } from './modal.component';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
    ],
    exports: [ModalNComponent],
    declarations: [ModalNComponent],
    providers: [ModalServiceN]
})
export class ModalModule { }
