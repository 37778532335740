import { Component, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
import { merge, fromEvent, of, combineLatest, ReplaySubject, Observable } from 'rxjs';
import { mapTo, map, switchMap, shareReplay } from 'rxjs/operators';
import { Route } from 'src/app/shared/sl-data/route';
import { toPromise } from 'src/app/shared/sl-data/util';
import { TimeConfirmationRequestModal } from '../../time-confirmation-request/time-confirmation-request-modal.service';
import { TimeConfirmationResultsModal } from '../../time-confirmation-results/time-confirmation-results-modal.service';

type Stat = 'all_confirmed'
    | 'partial'
    | 'all_sent'
    | null;

@Component({
    selector: 'app-confirmations-icon',
    templateUrl: './confirmations-icon.component.html',
    styleUrls: ['./confirmations-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationsIconComponent {
    @Input() set route(route: Route) {
        this.route$.next(route);
    }
    @Input() public interactive = true;
    private route$ = new ReplaySubject<Route>(1);
    private hovering$ = merge(
        fromEvent(this.elRef.nativeElement, 'mouseover').pipe(mapTo(true)),
        fromEvent(this.elRef.nativeElement, 'mouseleave').pipe(mapTo(false)),
        of(false),
    );

    constructor(
        private elRef: ElementRef,
        private timeConfirmationReqModal: TimeConfirmationRequestModal,
        private timeConfirmationResultsModal: TimeConfirmationResultsModal,
    ) { }

    public confirmationsStatus$ = (
        this.route$.pipe(
            switchMap((route: Route) => route.stops$),
            map(stops => {
                const confirmations = stops.map(stop => stop.status === 'completed' || stop.status === 'delivered' || stop.status === 'not_home' ? 'confirmed' : stop.scheduling.customerConfirmation);
                if (confirmations.every(conf => conf === 'confirmed' || conf === 'not_applicable')) {
                    const qty = confirmations.length;
                    return { stat: 'all_confirmed', qty };
                } else if (confirmations.some(conf => conf && conf !== 'confirmed' && conf !== 'sent' && conf !== 'not_applicable')) {
                    const qty = confirmations.filter(conf => conf && conf !== 'confirmed' && conf !== 'sent' && conf !== 'not_applicable').length;
                    return { stat: 'partial', qty };
                } else if (confirmations.every(conf => conf === 'sent' || conf === 'confirmed' || conf === 'not_applicable')) {
                    const qty = confirmations.filter(conf => conf === 'sent').length;
                    return { stat: 'all_sent', qty };
                } else {
                    const qty = confirmations.filter(conf => !conf).length;
                    return { stat: null, qty };
                }
            }),
            shareReplay(1)
        ) as Observable<{ qty: number; stat: Stat; }>
    );

    public tooltip$ = this.confirmationsStatus$
        .pipe(map(stat => {
            switch (stat.stat) {
                case null:
                    return {
                        color: '#252E3D',
                        text: 'Request time confirmation',
                    };
                case 'all_sent':
                    return {
                        color: '#777787',
                        text: 'Pending confirmations',
                    };
                case 'all_confirmed':
                    return {
                        color: '#03A524',
                        text: 'Everyone has confirmed',
                    };
                case 'partial':
                    return {
                        color: '#F0412C',
                        text: 'Actions required',
                    };
            }
        })
        );

    public component$ = (
        combineLatest(
            this.hovering$,
            this.confirmationsStatus$
        ).pipe(
            map(([hovering, stat]) => {
                switch (stat.stat) {
                    case null:
                        return {
                            color: '#777787',
                            icon: 'clock',
                            iconWidth: '20px',
                            border: false,
                        };
                    case 'all_sent':
                        return {
                            color: '#777787',
                            iconWidth: '12px',
                            border: true,
                            text: stat.qty,
                        };
                    case 'all_confirmed':
                        return {
                            color: '#03A524',
                            iconWidth: '12px',
                            border: true,
                            text: stat.qty,
                        };
                    case 'partial':
                        return {
                            color: '#F0412C',
                            border: true,
                            text: stat.qty,
                        };
                }
            })
        )
    );

    public async clickHandler(ev: MouseEvent) {
        ev.stopPropagation();

        if (!this.interactive) {
            return;
        }
        const { stat } = await toPromise(this.confirmationsStatus$);
        const route = await toPromise(this.route$);
        if (stat === null) {
            this.timeConfirmationReqModal.show({ route });
        } else {
            this.timeConfirmationResultsModal.show({ route });
        }
    }

}
