import { Injectable } from '@angular/core';
import { PlanningState } from '../planning/state';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ParamConfigService {
    constructor(
        private planningState: PlanningState,
        private route: ActivatedRoute,
    ) { }

    init() {
        this.route.queryParamMap.subscribe(params => {
            params.keys.forEach(key => this.options(key, params.get(key)));
        });
    }

    private options(key: any, value: any) {
        const options = [
            { key: 'date', action: (d) => this.planningState.setSelectedDate(d) }
        ];

        const option = options.find(opt => opt.key === key);

        if (option) {
            return option.action(value);
        }
    }


}
