import { Injectable } from '@angular/core';
import { DataEnvService } from '../sl-data/data-env.service';
import { toPromise } from '../sl-data/util';

@Injectable()
export class SlServicesPathsService {

    constructor(
        private dataEnv: DataEnvService,
    ) { }

    public async requestRouteConfirmation(routeId: string) {
        const comId = await this.comId();
        return `requestRouteConfirmation/${comId}/${routeId}`;
    }

    public async sendMessageToCustomers() {
        const comId = await this.comId();
        return `sendMessageToCustomers/${comId}`;
    }

    private async comId() {
        return toPromise(this.dataEnv.comId$);
    }
}
