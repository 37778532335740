import { Component, OnInit, ChangeDetectionStrategy, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DataPathsService } from 'src/app/shared/sl-data/data-paths.service';
import { toPromise } from 'src/app/services/utils';
import { CarrierData } from 'src/app/shared/sl-data/carrier';
import { MainNavStateService } from 'src/app/components/main-nav/state.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateUserModalComponent implements OnInit {
  public editForm: FormGroup;
  public hide = true;
  public operationInProg$ = new BehaviorSubject<string|null>(null);
  constructor(public dialogRef: MatDialogRef<CreateUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, private carrierData: CarrierData, public mainNav: MainNavStateService, private utilities:UtilitiesService) {
  }

  async ngOnInit() {
    this.editForm = this.fb.group({
      'email': ['', Validators.compose([Validators.email])],
      'password': ['', Validators.compose([Validators.required])]
    });
    this.editForm.patchValue(this.data);
  }

  async onEditFormSubmit(value) {
    try{
      this.operationInProg$.next(!this.data.userId ? 'CREATE' : 'UPDATE');
      if(!this.data.userId){
        await this.carrierData.createCarrierUser(this.data.carrierId, this.data.email, value.password);
      }
      else{
        await this.carrierData.updateCarrierUser(this.data.carrierId, this.data.userId, this.data.email, value.password);
      }
      
      this.dialogRef.close();
      this.data.parent.close();
      this.utilities.notifySuccess(!this.data.userId ? 'Carrier user created successfully.' : 'Carrier user updated successfully.');
    }
    catch(err){
        if(err.details){
            this.utilities.notifyError(err.details);
            return;
        }
        this.utilities.notifyError(!this.data.userId ? 'Unexpected error creating carrier user. Please contact system admin' : 'Unexpected error updating carrier user. Please contact system admin');
    }
    finally{
      this.operationInProg$.next(null);
    }
  }

  close() {
    this.dialogRef.close();
  }

  async delete() {
    try{
      this.operationInProg$.next('DELETE');
      await this.carrierData.deleteCarrierUser(this.data.carrierId, this.data.userId);
      this.utilities.notifySuccess('Carrier user deleted successfully.');
      this.dialogRef.close();
      this.data.parent.close();
    }
    catch(err){
        if(err.details){
            this.utilities.notifyError(err.details);
            return;
        }
        this.utilities.notifyError('Unexpected error deleting carrier user. Please contact system admin');
    }
    finally{
      this.operationInProg$.next(null);
    }
  }
}
