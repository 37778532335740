import { Injectable } from '@angular/core';
import { Chat } from 'src/typings/job';
import { DataService, WithDocId } from '../data.service';
import { DataPathsService } from '../data-paths.service';
import { switchMap, map, first } from 'rxjs/operators';
import { toPromise, capitalizeFirstLetter } from '../util';
import { JobDatService, Job } from '../job';
import { of, from, merge, combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { SlPlanningService } from '../../sl-planning/sl-planning.service';
import { DataEnvService } from '../data-env.service';
import { SlServicesService } from '../../sl-services/sl-services.service';
import moment from 'moment';
import { AuthService } from 'src/app/core/auth.service';
import { RouteDataService } from '../route';
import { CarrierMessageListDoc, MessageDoc, NotificationDoc } from './messages-types';

@Injectable({
    providedIn: 'root'
}) 
export class MessagesData {

    constructor(
        private dataCtrl: DataService,
        private dataPaths: DataPathsService,
        private services: SlServicesService
    ) { }

    //TODO: accept a parameter for last N days, which defaults to 5
    public latestChatMessages$(carrierId: string, limit: number = 5) : Observable<MessageDoc[]> {
        const flatMessages$ = new BehaviorSubject([]);
        return this.dataPaths.carrierMessages$(carrierId)
            .pipe(switchMap(path => {
                //TODO: filter query by last N days
                this.dataCtrl.getCollection$<CarrierMessageListDoc>(
                    {
                        path,
                        orderBy: "date", orderDirection: "desc",
                        limit
                    }).subscribe((res) => {
                    const flatMessages = [];
                    res.forEach((messageOfDay) => {
                        flatMessages.push(...messageOfDay.messages);
                    });
                    flatMessages$.next(flatMessages);
                
                });
                return flatMessages$;  
        }));
    }

    public notifications$ = this.dataPaths.notifications$.pipe(
        switchMap(path => this.dataCtrl.getCollection$<NotificationDoc>({ path })),
    );

    public async removeAllNotifications(authorType: 'client' | 'driver' | '' = '') {
        return await this.services.callService(`removeAllNotifications`, { authorType });
    }
}
