<div class="header">
    <div class="from"><app-icon name="person2"></app-icon><div>{{userName}}</div></div>
    <div class="live-date" *ngIf="liveDate$ | async" [innerHTML]="(liveDate$ | async) | appDate:'day'"></div>
    <div class="to" *ngIf="otherRecipient" [innerText]="otherRecipient"></div>
</div>

<div class="logs" cdk-scrollable>
    <button *ngIf="(showMoreMessagesButton$ | async)" (click)="viewMoreMessagesHandler()">View more messages</button>
    <div class="log-item" *ngFor="let log of messageLogs$ | async; trackBy: trackByFn">
        <div class="date-separator" #dateSep *ngIf="log.type === 'date-separator'" [id]="log.id">
            {{log.date | appDate:'day'}}
        </div>
        <div class="message-container"
            *ngIf="log.type === 'message'"
            [ngClass]="log.cssClass">
            <app-icon *ngIf="log.showUserIcon" [ngClass]="log.showTime ? 'with-time' : ''" name="person2"></app-icon>
            <div class="message">
                <div class="message-from">{{log.cssClass==='my-message' ? 'Me' : userName}}</div>
                <div class="content" [ngClass]="log.isCall ? 'call' : ''"> 
                    <app-icon *ngIf="!log.isCall" class="left-icon" name="chat"></app-icon>
                    <app-icon *ngIf="log.isCall" class="left-icon" name="phone"></app-icon>
                    <div class="message-text-and-images" *ngIf="(messageFiles$ | async) as messageFiles">
                        <img *ngFor="let imageRelativePath of log.images" [src]="mapMessageUrl(imageRelativePath,messageFiles)" (click)="displayImage($event.target.src)">
                        <video *ngFor="let imageRelativePath of log.videos" width="100%" controls><source src="{{mapMessageUrl(imageRelativePath, messageFiles)}}" type="video/mp4"></video>
                        <span class="message-text">{{log.message}}</span>
                    </div>
                    <div class="message-checks">
                        <app-icon *ngIf="log.sent && log.cssClass==='my-message'" [ngStyle]="{'color':log.read ? 'blue': 'black'}" name="check"></app-icon>
                        <app-icon *ngIf="(log.delivered || log.read) && log.cssClass==='my-message'" [ngStyle]="{'color':log.read ? 'blue': 'black'}" name="check"></app-icon>
                    </div>
                </div>
                <div class="time">{{log.date | appDate | async}}</div>
            </div>
        </div>
    </div>
</div>
<div class="wysiwyg-container" >
    <div class="wysiwyg"><textarea class="textarea-message" (keypress)="onKeyPressed($event);" [(ngModel)]="textMessage" rows="3" maxlength="160" placeholder="Type message..."></textarea></div>
    <div (click)="sendMessageClickHandler()" class="send-message"><app-icon name="send-message" ></app-icon></div>
</div>
<div class="image-viewer" *ngIf="currentImage$ | async">
    <div><app-icon class="close-icon" name="close-window-cross" (click)="closeImage()"></app-icon></div>
    <img [src]="currentImage$ | async">
</div>
