<mat-card class="login-card mat-elevation-z6">
    <div class="title">Login </div>
    <form #form (submit)="login(); $event.preventDefault()" class="login-form" action="#">
        <mat-form-field fullWidth>
            <input matInput  [value]="email$ | async" (change)="email$.next($event.target.value)" required type="email" placeholder="Email">
        </mat-form-field>

        <mat-form-field>
            <input matInput [value]="password$ | async" (change)="password$.next($event.target.value)" required type="password"
                placeholder="Password">                
        </mat-form-field>
        <div class="forgot">
            <div (click)="resetPassword()" >Reset Password</div>
        </div>
     
        <button class="login-button" [disabled]="!(email$ | async) || !(password$ | async) || (loading$ | async)" [ngClass]="{'btn-loading': (loading$ | async)}" mat-raised-button>
            {{(loading$ | async) ?'':'Login'}}  <mat-progress-spinner  *ngIf="(loading$ | async)" strokeWidth="3" diameter="25" mode="indeterminate"></mat-progress-spinner>
         </button>
    </form>
</mat-card>