<form [formGroup]="editForm" (ngSubmit)="onEditFormSubmit(editForm.value)">
    <h2 *ngIf="!data.userId" mat-dialog-title>Create User</h2>
    <h2 *ngIf="data.userId" mat-dialog-title>Update User</h2>
    <mat-dialog-content>   
            <div class="row">
                <div >
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input readonly matInput formControlName="email" type="text" />
                    </mat-form-field>
                </div>
                <div >
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input matInput formControlName="password" placeholder="Password" [type]="hide ? 'password' : 'text'" />
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>        
            </div>
    
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="row w-100"> 
            <div class="col-lg-6 text-right row">
                <button *ngIf="data.userId" mat-raised-button class="delete-button" type="button" (click)="delete()" [innerText]="(operationInProg$ | async)==='DELETE' ? 'Deleting...' : 'Delete'"></button>
            </div>
            <div class="col-lg-6 text-right" align="end">
                <button mat-raised-button type="button" (click)="close()">Cancel</button>
                <button mat-raised-button type="submit" color="primary" [innerText]="(operationInProg$ | async)==='UPDATE' ? 'Updating...' : (operationInProg$ | async)==='CREATE' ? 'Creating...' : 'Save'"></button>
            </div>
        </div>      
    </mat-dialog-actions>
</form>