import { Injectable } from '@angular/core';
import { TimeConfirmationResultsCompProps, TimeConfirmationResultsComponent } from './time-confirmation-results.component';
import { ModalServiceN } from '../../modal/modal.service';

@Injectable()
export class TimeConfirmationResultsModal {
    constructor(
        private modal: ModalServiceN,
    ) { }

    public show(props: TimeConfirmationResultsCompProps) {
        this.modal.show(
            TimeConfirmationResultsComponent,
            props
        );
    }
}
