import {
    Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, OnInit, ViewChildren, QueryList, AfterViewInit
} from '@angular/core';
import { Observable, BehaviorSubject, combineLatest} from 'rxjs';
import { Message } from 'src/typings/communication';
import { map, filter, first, delay, skip, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DateUtilsService } from 'src/app/services/date-utils.service';
import { compareObjectArrays } from 'src/app/services/utils';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/overlay';
import { maxBy } from 'lodash';
import { MessageDoc, FileWithType } from 'src/app/shared/sl-data/messages/messages-types';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ref, getDownloadURL} from '@angular/fire/storage';


interface MessageLog extends Message {
    type: 'message' | 'dates-separator';
    date: Date;
    id: string;
    cssClass: 'my-message' | '';
    showUserIcon: boolean;
    showTime: boolean;
    sent: boolean;
    read: boolean;
}

@Component({
    selector: 'app-chat-box',
    templateUrl: './chat-box.component.html',
    styleUrls: ['./chat-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBoxComponent implements OnInit, AfterViewInit {
    private messages$ = new BehaviorSubject<MessageDoc[]>([]);
    private unsentMessages$ = new BehaviorSubject<MessageDoc[]>([]);
    public showMoreMessagesButton$ = new BehaviorSubject<boolean>(false);
    public liveDate$: Observable<Date>;
    private currentMessagesDaysLimit : number = 5;
    public textMessage:string = '';
    
    @ViewChild(CdkScrollable, { static: true }) private messagesContainer: CdkScrollable;
    @ViewChildren('dateSep') private dateSeparators: QueryList<ElementRef>;

    private showMoreMessagesButton = (msgs: MessageDoc[]) => {
        const messageDays = msgs.reduce((appDates, currentValue) => {
            const currentDate = currentValue.dateUtc.toDate().toISOString().split('T')[0].toString();    
            !appDates.includes(currentDate) && appDates.push(currentDate);
            return appDates;
        },[]);
        if(this.viewMoreMessagesCallback && this.currentMessagesDaysLimit === messageDays.length){
            this.showMoreMessagesButton$.next(true);
        }
    }

    private _id: string = '';
    @Input() set id(id: string) {//Use to detect a context change and update the component state.
        if(this._id !== id){
            this.textMessage = '';
            this.unsentMessages$.next([]);
        }
        this._id=id;
    } 


    @Input() otherRecipient : string;
    @Input() userName : string;
    @Input() displayDate : boolean = true;
    
    @Input() set messages(messages: MessageDoc[]) {
        //TODO: fix this. This hack is needed until the root cause of this bug is found:
        //In some cases, when there are no messages, this event triggers in an infinite loop
        //This ensure this loop is closed, but still need to find the root cause.
        if(this.messages$.value.length === 0 && messages && messages.length === 0){
            return;
        }
        //-----------------------------------------------------------------------------------
        
        this.messages$.next(messages || []);
        messages && this.showMoreMessagesButton(messages);
    }

    public messageFiles$ = this.messages$.pipe(
        switchMap(async messages => await this.loadMessageFilesPublicUrls(messages))
    );

    public combinedMessages$ = combineLatest(this.messages$, this.unsentMessages$).pipe(
        map(([messages, unsentMessages]) => {
            const ago = moment().subtract(2, 'minutes').toDate();
            unsentMessages = unsentMessages.filter((unsentMessage) => !messages.some((sentMessage) => unsentMessage.message === sentMessage.message && sentMessage.dateUtc.toDate() > ago));
            //unsent messages where sent
            if(unsentMessages.length < this.unsentMessages$.value.length){
                this.unsentMessages$.next(unsentMessages);
            }
            return messages.concat(unsentMessages);
        })
    );
    
    @Input() viewMoreMessagesCallback : (limit : number) => void;

    @Input() addMessageCallback : (message : string) => Promise<void>;
    
    ngAfterViewInit(): void {
        if(!this.displayDate){
            this.liveDate$ = new BehaviorSubject(null);
        }
        else{
            this.liveDate$ = combineLatest(
                this.scroller.scrolled(250),
                this.dateSepElements$.pipe(
                    distinctUntilChanged((prev, cur) => cur.every(curEl => !!prev.find(prevEl => prevEl.id === curEl.id)))
                )
            ).pipe(
                map(([, separators]) => {
                    const headerHeight = 120;
                    return separators
                        .map(el => ({ el, fromTop: el.getBoundingClientRect().top - headerHeight }))
                        .filter(sep => sep.fromTop < 0);
                }),
                map(sepsAboveTop => {
                    const firstAboveTop = maxBy(sepsAboveTop, 'fromTop');
                    if (firstAboveTop) {
                        return new Date(firstAboveTop.el.id);
                    }
                })
            );
        }        
    }

    ngOnInit(): void {
    }

    constructor(
        private dateUtils: DateUtilsService,
        private scroller: ScrollDispatcher,
        private storage: AngularFireStorage
    ) {
        this.messageLogs$.pipe(
            first(),
            delay(40)
        ).subscribe(() => this.scrollBottom('auto'));

        this.messageLogs$.pipe(
            skip(1),
            delay(40)
        ).subscribe(() => this.scrollBottom('smooth'));
    }

    private get dateSepElements$() {
        return this.dateSeparators.changes.pipe(
            map<QueryList<ElementRef>, HTMLElement[]>(dateSeps =>
                dateSeps.toArray().map((elRef: ElementRef) => elRef.nativeElement)
            )
        );
    }

    private sendMessage = async () => {
        const content = this.textMessage.trim();
        if (content !== '') {
            this.clearEditor();
            this.unsentMessages$.next(this.unsentMessages$.value.concat([{message: content, from: 1, dateUtc: Timestamp.now(), isUnread: true, unsent: true}]));
            await this.addMessageCallback(content);
        }
    }

    public sendMessageClickHandler = async () => {
        await this.sendMessage();
    }

    public viewMoreMessagesHandler = async () => {
        this.currentMessagesDaysLimit +=5;
        this.viewMoreMessagesCallback(this.currentMessagesDaysLimit);
    }
    
    public messageLogs$: Observable<MessageLog[]> = (
        this.combinedMessages$.pipe(
            filter(messages => !!messages),
            distinctUntilChanged((previousMessages, newMessages) => {
                return compareObjectArrays(previousMessages, newMessages)
            }),
            map(messages => messages
                .sort((msg1, msg2) =>
                    msg1.dateUtc.seconds - msg2.dateUtc.seconds
                )
                .map(msg => ({
                    ...msg,
                    cssClass: msg.from === 1 ? 'my-message' : '',
                    showUserIcon: msg.from !== 1,
                    sent: !msg.unsent,
                    read: msg.status === 'read' || !msg.isUnread,
                    delivered: msg.status === 'delivered',
                    images: msg.filesWithType ? msg.filesWithType.filter(fwt=>fwt.contentType.indexOf('image')>=0).map(fwt=>fwt.relativePath) : msg.files,
                    videos: msg.filesWithType ? msg.filesWithType.filter(fwt=>fwt.contentType.indexOf('video')>=0).map(fwt=>fwt.relativePath) : []
                }))
                .reduce(({ lastDisplayedDay: lastDay, lastDisplayedTime: lastMessageTime, messages }, msg, index, origArr) => {
                    const msgDate = msg.dateUtc.toDate();
                    const isNewDay = !this.dateUtils.isSameDay(msgDate, lastDay);
                    const sixtySecondsPassedSinceLast = !this.dateUtils.isWithinMinute(lastMessageTime, msgDate);
                    const previousMessage = origArr[index - 1];
                    const isSamePartyAsPrevious = previousMessage && previousMessage.from === msg.from;
                    const showTime = sixtySecondsPassedSinceLast || !isSamePartyAsPrevious;
                    return {
                        messages: [
                            ...messages,
                            ...(isNewDay ? [{
                                type: 'date-separator',
                                date: msgDate,
                                id: this.dateUtils.dateToISO(msgDate)
                            }] : []),
                            {
                                ...msg,
                                type: 'message',
                                date: msgDate,
                                showTime,
                            },
                        ],
                        lastDisplayedDay: isNewDay ? msgDate : lastDay,
                        lastDisplayedTime: showTime ? msgDate : lastMessageTime,

                    };
                }, { lastDisplayedDay: null, lastDisplayedTime: null, messages: [] }).messages
            )
        )
    );

    private clearEditor() {
        this.textMessage = '';
    }

    public trackByFn(i, msg) {
        return i;
    }

    private scrollBottom(behavior: | 'smooth' | 'auto' = 'smooth') {
        this.messagesContainer.scrollTo({ bottom: 0, behavior });
    }

    private loadMessageFilesPublicUrls = async (messages: MessageDoc[]) => {
        const images = [];
        for (let mIndex = 0; mIndex < (messages ?? []).length; mIndex++) {
            const message = messages[mIndex];
            const files: FileWithType[] = message.filesWithType ? message.filesWithType : (message.files?.map(relativePath=>{ return {relativePath,contentType:'image/jpg'} }) || []);
            for (let fIndex = 0; fIndex < files.length; fIndex++) {
                const fileWithType = files[fIndex];
                const imgRef = ref(this.storage.storage, fileWithType.relativePath);
                const url = await getDownloadURL(imgRef);
                images.push({relativePath: fileWithType.relativePath, publicPath: url, contentType: fileWithType.contentType});
            }
        }
        return images;
    }

    private mapMessageUrl(imageRelativePath, mappingToPublicUrl){
        if(mappingToPublicUrl){
            const publicPath = mappingToPublicUrl.find(m=>m.relativePath===imageRelativePath).publicPath;
            return publicPath;
        }
    }

    public currentImage$ = new BehaviorSubject('');

    public displayImage = (imageUrl) => {
        this.currentImage$.next(imageUrl);
    }

    public closeImage = () => {
        this.currentImage$.next('');
    }

    public onKeyPressed = async (event) => {
        if(event.keyCode===13){
            await this.sendMessage();
            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    }
    
}
