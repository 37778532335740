import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class SlServicesService {

    constructor(
        private fns: AngularFireFunctions,
    ) {
        if (window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost')) {
            console.log('using local backend');
            fns.useFunctionsEmulator('http://localhost:5001');
        }
    }

    public async callService(path: string, data: any = {}) {
        const callable = this.fns.httpsCallable(path);
        return callable(data).toPromise();
    }
}
