
<h4 mat-dialog-title>Reset password </h4>
<div mat-dialog-content>
    <div class="reset-area">
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput [value]="email$ | async" (change)="email$.next($event.target.value)" required type="email" />
        </mat-form-field>
    </div>
    <div mat-dialog-actions  align="end">        
        <button class="login-button" [disabled]="!(email$ | async) || (loading$ | async)" (click)="reset()" [ngClass]="{'btn-loading': (loading$ | async)}" mat-raised-button>
           {{(loading$ | async) ?'':'Reset'}}  <mat-progress-spinner  *ngIf="(loading$ | async)" strokeWidth="3" diameter="25" mode="indeterminate"></mat-progress-spinner>
        </button>
    </div>
</div>
