import {
    Directive, Inject, ComponentFactoryResolver, ViewContainerRef,
    OnInit, Input, HostListener, ElementRef, HostBinding, AfterViewInit, ViewRef, Renderer2} from '@angular/core';
import { AppTooltipComponent } from './tooltip.component';

@Directive({
    selector: '[appTooltip]'
})
export class TooltipDirective implements AfterViewInit {
    private tooltipComp: AppTooltipComponent;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private elRef: ElementRef,
        private renderer: Renderer2,
        @Inject(ComponentFactoryResolver) private resolver: ComponentFactoryResolver,
    ) {
        this.elRef.nativeElement.style.position = 'relative';
    }

    ngAfterViewInit() {
        const factory = this.resolver.resolveComponentFactory(AppTooltipComponent);
        const componentRef = this.viewContainerRef.createComponent(factory, 0);
        this.tooltipComp = componentRef.instance;
        this.renderer.appendChild(this.elRef.nativeElement, this.tooltipComp.elRef.nativeElement),
        this.tooltipComp.text = this.tooltipText;
        this.tooltipComp.positionY = this.positionY;
        this.tooltipComp.offsetX = -5;
        if (this.color) {
            this.tooltipComp.color = this.color;
        }
    }

    @Input('appTooltip') tooltipText = 'empty';
    @Input() positionY: 'left' | 'right' = 'right';
    @Input() color: string;

    @HostListener('mouseenter') onMouseEnter() {
        this.tooltipComp.show = true;
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.tooltipComp.show = false;
    }

}
