import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ParamConfigService } from './core/param-config.service';
import { UpdateService } from './services/update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    constructor(urlConfig: ParamConfigService, private updateService: UpdateService) {
        urlConfig.init();
    }
}
