import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {
  public email$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject(false);
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService, private utilities: UtilitiesService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this.email$.next(this.data.email)
  }

  public reset() {
    this.loading$.next(true);
    const email = this.email$.value;
    this.auth.resetPassword(email)
      .then(() => {
        this.utilities.notifySuccess('Email Sent');
        this.loading$.next(false);
        this.dialogRef.close();
      })
      .catch(() => {
        this.utilities.notifyError('Password reset issue');
        this.loading$.next(false);
      });

  }

}
