import { Component, ChangeDetectionStrategy, Injectable, InjectionToken, Inject, Injector, Input, AfterViewInit } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { first, map, filter, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, ReplaySubject, Observable, of } from 'rxjs';
import { ModalServiceN } from 'src/app/shared/modal/modal.service';
import { Job } from 'src/app/shared/sl-data/job';
import { Route } from 'src/app/shared/sl-data/route';
import { toPromise } from 'src/app/shared/sl-data/util';
import { JobDetailsTab } from './stop-view/stop-view.component';
import { RouteDetailsTab } from './route-view/route-view.component';
import { PlanningState } from 'src/app/planning/state';

interface DetailsViewProps {
    view?: 'stop' | 'route';
    job$?: BehaviorSubject<Job>;
    route$?: BehaviorSubject<Route>;
    jobTab?: JobDetailsTab;
    routeTab?: RouteDetailsTab;
}

type View = 'route' | 'stop' | null;

@Component({
    selector: 'app-details-view',
    templateUrl: './details-view.component.html',
    styleUrls: ['./details-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsComponent implements AfterViewInit {
    public selectedView$ = new BehaviorSubject<View>(null);
    public routeViewTitle$ = new ReplaySubject(1);
    public jobViewTitle$ = new ReplaySubject(1);
    
    @Input() view: View;
    @Input() route$: BehaviorSubject<Route> = new BehaviorSubject<Route>(null);
    @Input() job$: BehaviorSubject<Job> = new BehaviorSubject<Job>(null);
    @Input() jobTab: JobDetailsTab = 'items';
    @Input() routeTab: RouteDetailsTab = 'messages';

    constructor(
        private routing: PlanningState,
    ) { }

    async ngAfterViewInit() {
        if (this.view) {
            this.selectedView$.next(this.view);
        }
        if (this.route$.value) {
            this.routeViewTitle$.next('Route');
        } else {
            this.routeViewTitle$.next('Selected Route');
            this.routing.selectedStopList$.pipe(
                filter(list => (list && list.type === 'route')),
                map(list => list.route),
                startWith(null)
            ).subscribe(this.route$);
        }
        if (this.job$.value) {
            this.jobViewTitle$.next('Job');
        } else {
            this.jobViewTitle$.next('Selected Job');
            this.routing.activeStop$.pipe(
                startWith(null)
            ).subscribe(this.job$);
        }
    }

    public selectedRouteO$ = (
        this.routing.selectedStopList$.pipe(
            filter(list => list && list['type'] === 'route')
        )
    );

    public $tabs = combineLatest(
        this.selectedView$,
        this.routeViewTitle$,
        this.jobViewTitle$,
    ).pipe(
        map(([
            view,
            routeTitle,
            jobTitle,
        ]) => {
            if (!this.route$.value || !this.job$.value) {
                return null;
            }
            return [{
                icon: 'truck',
                title: routeTitle,
                color: this.route$.value?.color,
                id: 'route',
                cssClass: view === 'route' ? 'selected' : '',
                pin: view === 'route' ? true : false,
                clickHandler: () => this.selectView('route')
            },
            {
                icon: 'pin',
                title: jobTitle,
                color: '#757681',
                id: 'stop',
                cssClass: view === 'stop' ? 'selected' : '',
                pin: view === 'stop' ? true : false,
                clickHandler: () => this.selectView('stop')
            },
            ].filter(Boolean);
        }));

    public selectView(view: View) {
        this.selectedView$.next(view);
    }

    public trackByFn(i, t) {
        return i;
    }

}

@Injectable()
export class DetailsModalService {
    constructor(
        private modalCtrl: ModalServiceN,
    ) { }

    private overlayConfig: OverlayConfig = {
        height: 'calc(100vh - 50px)',
        width: '900px',
    };

    public show(data: DetailsViewProps) {
        this.modalCtrl.show(DetailsComponent, data, this.overlayConfig);
    }
}
