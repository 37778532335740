import { Component, OnInit, ChangeDetectionStrategy, Injectable, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalServiceN } from '../../modal/modal.service';

export interface DialogComponentProps {
    header: string;
    body: string;
    okFn?: (userInput: string) => void;
    userInputLabel?: string;
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent  {
    @Input() header: string;
    @Input() body: string;
    @Input() dismissFn: () => void;
    @Input() okFn: (userInput: string) => void;
    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
    @Input() userInputLabel: string;

    constructor(
    ) { }

    public okHandler() {
        this.dismissFn();
        this.okFn && this.okFn(this.userInput?.nativeElement.value);
    }

    public cancelHandler() {
        this.dismissFn();
    }

}

@Injectable()
export class DialogLauncher {
    constructor(private modal: ModalServiceN) { }

    public launch(props: DialogComponentProps) {
        this.modal.show(DialogComponent, props);
    }
}
