import { Injectable } from '@angular/core';
import { SlServicesPathsService } from '../sl-services/sl-services-paths.service';
import { SlServicesService } from '../sl-services/sl-services.service';

@Injectable()
export class SlCustomerConfirmationService {

  constructor(
      private servicesPaths: SlServicesPathsService,
      private services: SlServicesService
  ) { }

  public async requestRouteConfirmation(routeId: string, onlyRemaining = false, individualCall = false) {
      const path = await this.servicesPaths.requestRouteConfirmation(routeId);
      return this.services.callService(path, {onlyRemaining, individualCall});
  }

  public async sendMessageToCustomers(selectedDate: string, onlyRemaining = false) {
    const path = await this.servicesPaths.sendMessageToCustomers();
    return this.services.callService(path, {selectedDate, onlyRemaining});
}
}
