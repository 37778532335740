<div class="tabs"
    *ngIf="$tabs | async as tabs">
    <div class="tab" *ngFor="let tab of tabs; trackBy: trackByFn"
        [ngClass]="tab.cssClass"
        (click)="tab.clickHandler()"
        [style.background-color]="tab.color">
        <div class="tab-inner">
            <div class="pin" *ngIf="tab.pin"></div>
            <app-icon [name]="tab.icon"></app-icon>
            <div class="title" [innerText]="tab.title"></div>
        </div>
    </div>
</div>

<app-stop-view
    *ngIf="(selectedView$ | async) === 'stop'"
    [stop]="job$ | async"
    [tab]="jobTab"
    ></app-stop-view>
<app-route-view
    *ngIf="(selectedView$ | async) === 'route'"
    [route]="(route$ | async)"
    [tab]="routeTab"></app-route-view>
