import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(updates: SwUpdate) {
    console.log('Initialize update service');
    //Check for updates every 10 min
    interval(10 * 60 * 1000).subscribe(() => {
      console.log('Checking for new versions');
      updates.checkForUpdate().then(updateAvailable=> {
        if(updateAvailable){
          console.log('New version ready');
          if (confirm('New version available. Load New Version?')) {
            window.location.reload();
          }
        }
      });
    });
  }
}
