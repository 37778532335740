import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Job } from 'src/app/shared/sl-data/job';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent implements OnInit {
    @Input() job: Job;
    constructor() { }

    ngOnInit() {
    }

}
