import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlPlanningService } from './sl-planning.service';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { CoreModule } from 'src/app/core/core.module';
import { SlDataModule } from '../sl-data/sl-data.module';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        SlDataModule,
    ],
    declarations: [],
    providers: []
})
export class SlPlanningModule { }
