<app-card
    header="Time confirmation request for this route">
    <app-route-cardn
        [route]="route"
        [interactive]="false"
        [collapsed]="false"
        [showMessages]="false"
        ></app-route-cardn>
        <div class="buttons">
            <app-card-button
                (click)="cancelHandler()"
                [innerText]="(requestInProg$ | async) ? 'Close' : 'Cancel'">
            </app-card-button>
            <app-card-button
                (click)="requestHandler()"
                [color]="route.color"
                [disabled]="requestInProg$ | async"
                [innerText]="(requestInProg$ | async)
                    ? 'Requesting...'
                    : 'Request confirmation'">
            </app-card-button>
        </div>
        <div class="footer-text">
            Send a time confirmation request to all clients of this route, in order to make sure they will be at the location when the carrier arrives.
        </div>
</app-card>