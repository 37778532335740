import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Route } from '../../sl-data/route';
import { SlCustomerConfirmationService } from '../../sl-customer-confirmation/sl-customer-confirmation.service';
import { BehaviorSubject } from 'rxjs';
import { DialogLauncher } from '../dialog/dialog.component';

export interface TimeConfirmationCompProps {
    route: Route;
}

@Component({
    selector: 'app-time-confirmation-request',
    templateUrl: './time-confirmation-request.component.html',
    styleUrls: ['./time-confirmation-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeConfirmationRequestComponent {
    @Input() public route: Route;
    @Input() dismissFn: () => void;
    public requestInProg$ = new BehaviorSubject(false);

    constructor(
        private confirmationSvc: SlCustomerConfirmationService,
        private dialog: DialogLauncher,
    ) { }

    public async requestHandler() {
        this.requestInProg$.next(true);
        await this.confirmationSvc
            .requestRouteConfirmation(this.route.id, true);
        this.requestInProg$.next(false);

        this.dismissFn();

        this.dialog.launch({
            header: 'Time confirmation request sent successfully',
            body: 'Results will be available on the main screen, per route.',
        });
    }

    public cancelHandler() {
        this.dismissFn();
    }
}
