import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Pipe({
    name: 'appDate'
})
export class DatePipe implements PipeTransform {

    constructor() { }

    transform(date: Date, args?: any): any {
        const m = moment(date);

        if (!m.isValid()) {
            return '';
        }

        if (args !== 'day') {
            return timer(0, 3000).pipe(
                map(() => {
                    const withinHour = (new Date().getTime() - date.getTime()) / 60 / 1000 < 60;
                    return withinHour
                        ? m.fromNow()
                        : m.format('hh:mm A');
                }),
                distinctUntilChanged()
            );
        }

        return m.calendar(null, {
            sameDay: '[Today], MMM DD YYYY',
            nextDay: '[Tomorrow], MMM DD YYYY',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday], MMM DD YYYY',
            lastWeek: 'dddd, MMM DD YYYY',
            sameElse: 'MMM DD YYYY'
        });
    }

}
