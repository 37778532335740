<mat-sidenav-container class="sidenav-container">

    <mat-sidenav  #drawer
        class="sidenav"
        fixedInViewport="true"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="!(isHandset$ | async) ? false : false" [ngClass]="{'nav-hide':isLoggin == true}">
        <mat-toolbar>Menu</mat-toolbar>
        <mat-nav-list>
            <a (click)="drawer.close()" mat-list-item routerLink="/routing">Routing</a>
            <a (click)="drawer.close()" mat-list-item routerLink="/carriers">Carrier List</a>
            <a (click)="drawer.close()" mat-list-item routerLink="/jobs">Jobs List</a>
            <button class="log-out-button" mat-button (click)="logOut();drawer.toggle()">
                Log out
                <mat-icon matListIcon>power_settings_new</mat-icon>
            </button>
            <div class="app-version">Version {{appInfo.version}}</div>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
        <div class="toolbar" *ngIf="isLoggin == true">
            <div class="left">
                <button   type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()">
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <img class="brand-logo"
                    src='assets/img/streamlion.png'>
                <span class="brand-name">StreamLion</span>
            </div>

            <div class="tabs">
                <div class="tab"
                    *ngFor="let tab of tabs$ | async"
                    [class.selected]="tab.selected"
                    (click)="tab.handler()">
                    <app-icon
                        [name]="tab.icon"></app-icon>
                    {{tab.label}}
                </div>
            </div>
            <div class="notifications">
                <!--<div class="notifications">
                    <app-icon name="bell"></app-icon>
                    <div class="count" [innerText]="1"></div>
                </div>-->
                <app-notifications></app-notifications>
            </div>
            <div class="user-menu">
                <div class="avatar">{{initials()}}</div>
                <div name="userName" class="username">{{username()}}</div>
            </div>
        </div>

        <router-outlet></router-outlet>

    </mat-sidenav-content>
</mat-sidenav-container>