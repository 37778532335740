import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Route } from '../../sl-data/route';
import { ReplaySubject, combineLatest, BehaviorSubject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { JobDoc } from '../../sl-data/job/job-type';
import { uniq } from 'lodash';
import { SlCustomerConfirmationService } from '../../sl-customer-confirmation/sl-customer-confirmation.service';
import { DialogLauncher } from '../dialog/dialog.component';


export interface TimeConfirmationResultsCompProps {
    route: Route;
}

@Component({
    selector: 'app-time-confirmation-results',
    templateUrl: './time-confirmation-results.component.html',
    styleUrls: ['./time-confirmation-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeConfirmationResultsComponent {
    public route$ = new ReplaySubject<Route>(1);
    public resendingInProg$ = new BehaviorSubject(false);
    @Input() dismissFn: () => void;
    @Input() set route(route: Route) {
        this.route$.next(route);
    }
    constructor(
        private confirmation: SlCustomerConfirmationService,
        private dialog: DialogLauncher,
    ) { }

    public stats$ = this.route$.pipe(
        switchMap(route => route.stops$),
        map(stops => {
            const confirmationTypes = uniq(stops.map(stop => stop.scheduling.customerConfirmation));
            return confirmationTypes
                .map(type => ({
                    count: stops.filter(stp => stp.scheduling.customerConfirmation === type).length,
                    type,
                    ...this.getConfirmationIcon(type),
                }));
        })
    );

    private getConfirmationIcon(stat: JobDoc['scheduling']['customerConfirmation']) {
        switch (stat) {
            case 'confirmed':
                return { color: '#222222', icon: 'thumbs-up', title: 'confirmed' };
            case 'rejected':
                return { color: '#F0412C', icon: 'thumbs-down', title: 'rejected' };
            case 'reschedule':
                return { color: '#F0412C', icon: 'thumbs-down', title: 'reschedule' };
            case 'no_response':
                return { color: '#FF8000', icon: 'question-circle', title: `no answer / busy` };
            case 'failed_delivery':
                return { color: '#FF8000', icon: 'question-circle', title: `Failed to deliver message` };
            case 'sent':
                return { color: '#777787', icon: 'three-dots', title: 'pending' };
            case 'customer_service':
                return { color: '#777787', icon: 'phone-alt', title: 'CSR' };
            default:
                return { color: '#777787', icon: '', title: 'Not sent' };
        }
    }

    public buttons$ = combineLatest(
        this.route$,
        this.stats$,
        this.resendingInProg$,
    ).pipe(
        map(([route, stats, resending]) => {
            const noResponse = stats.find(s => s.type === 'no_response');

            if (noResponse) {
                return [
                    {
                        label: resending ? 'Close' : 'Cancel',
                        handler: () => this.dismissFn()
                    },
                    {
                        label: (resending
                            ? `Resending to ${noResponse.count} clients`
                            : `Resend to ${noResponse.count} clients`
                        ),
                        handler: async () => {
                            this.resendingInProg$.next(true);
                            await this.confirmation.requestRouteConfirmation(route.id, true);
                            this.resendingInProg$.next(false);
                            this.dismissFn();
                            this.dialog.launch({
                                header: 'Time confirmation request sent successfully',
                                body: 'Results will be available on the main screen, per route.',
                            });
                        },
                        icon: noResponse.icon,
                        color: noResponse.color,
                        disabled: resending,
                    }
                ];
            } else {
                return [{
                    label: 'OK',
                    color: route.color,
                    handler: () => this.dismissFn()
                }];
            }
        })
    );

    public footerText$ = this.stats$.pipe(
        map(stats => {
            if (stats.find(stat => stat.type === 'no_response')) {
                // tslint:disable-next-line:max-line-length
                return 'Please click the "Resend" button above to send another confirmation request to the clients who did not answer the previous request.';
            }
        })
    );

}
