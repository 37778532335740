import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export const toPromise = <U>(ob: Observable<U>) => {
    return ob.pipe(first()).toPromise();
};

export const capitalizeFirstLetter = (string: any, isName = false) => {
    const stringLow = string.toLowerCase();
    if(isName){
        const stringArray =  stringLow.split(' ');
        stringArray.forEach((row: any,index: number) => {
            const str = row.toLowerCase();
            stringArray[index] = str.charAt(0).toUpperCase() + str.slice(1);
        });
        return stringArray.join(' ');
    }else{
        return stringLow.charAt(0).toUpperCase() + stringLow.slice(1);
    }  
}