import { Injectable } from '@angular/core';
import { Location } from '../shared/sl-data/location-types';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {

    constructor(private _snackBar: MatSnackBar) { }

    public formattedStopAddress = (address: Location, withCity = true, withState = false) => {
        const { city, state, streetName, streetNumber, apartment } = address;
        return `${streetNumber} ${streetName}`
            + (withCity ? `, ${city}` : '')
            + (withState ? `, ${state}` : '')
            + (apartment ? ` ${apartment}` : '');
    }

    public arrayFromNumber(num: number) {
        return Array.from(new Array(num));
    }

    notifySuccess(message: string) {
        this._snackBar.open(message, 'Close', {
            duration: 5000,
            panelClass: "notify-success"
        });
    }

    notifyError(message: string) {
        this._snackBar.open(message, 'Close', {
            duration: 6000,
            panelClass: "notify-error"
        });
    }

}
