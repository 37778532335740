<div class="header">
    <div class="year"
        [innerText]="headerYear$ | async"></div>
    <div class="date"
        [innerText]="headerDate$ | async"></div>
</div>
<mat-calendar #matCal
    (selectedChange)="dateChanged($event)"
    [dateFilter]="matCalDateFilter"
    [dateClass]="dateClass"
    [startAt]="activeDate$ | async"></mat-calendar>

<div class="footer">
    <div class="button cancel"
        (click)="cancelHandler()">Cancel</div>
    <div class="button ok"
        (click)="okayHandler()">OK</div>
</div>
        