import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent  {
    @Input() public percent;
}
