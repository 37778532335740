import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { map, first, switchMap } from 'rxjs/operators';
import { AppInfoService } from '../../services/app-info.service';
import { Router } from '@angular/router';
import { SlPlanningService } from 'src/app/shared/sl-planning/sl-planning.service';
import { PlanningState } from 'src/app/planning/state';
import { CollectionQuery, DataService } from 'src/app/shared/sl-data/data.service';
import { DataEnvService } from 'src/app/shared/sl-data/data-env.service';
import { MainNavStateService } from './state.service';
import { AuthService } from 'src/app/core/auth.service';
import { toPromise } from '../../services/utils';
import { DataPathsService } from 'src/app/shared/sl-data/data-paths.service';
import { ModalServiceN } from 'src/app/shared/modal/modal.service';
import { ShowSyncingErrorsComponent } from 'src/app/shared/sl-components/show-syncing-errors/show-syncing-errors.component';

@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavComponent implements OnInit {
    public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches)
        );
    public user: any;
    public isLoggin: any = false;
    public printBookReportButtonPressedAndWaiting$ = new BehaviorSubject(false);
    public planRouteButtonPressedAndWaiting$ = new BehaviorSubject(false);
    public syncStatus$ = new BehaviorSubject("up to date");
    public syncErrorJobs$ = new BehaviorSubject([]);

    constructor(
        public appInfo: AppInfoService,
        private auth: AuthService,
        private breakpointObserver: BreakpointObserver,
        public data: DataService,
        public env: DataEnvService,
        private router: Router,
        private planning: SlPlanningService,
        private state: PlanningState,
        private navState: MainNavStateService,
        private paths: DataPathsService,
        private modalCtrl: ModalServiceN,
    ) { }

    async ngOnInit() {
        this.auth.user$.subscribe((user) => {
            if (user == null) {
                this.isLoggin = false;
            } else {
                this.isLoggin = true;
            }
            this.user = user;
        });

        const path = await toPromise(this.paths.jobs$);
        this.data.getCollection$({
            path: path, queries: [['erp_sync_status', '==', 'error']].filter(Boolean) as CollectionQuery,
            orderBy: "scheduling.itinerary.date", orderDirection: "desc", limit: 10
        }).subscribe((res) => {
            if (res.length > 0) {
                this.syncStatus$.next("errors: " + res.length);

                this.syncErrorJobs$.next(res);
            }
            else {
                this.syncStatus$.next("up to date");

                this.syncErrorJobs$.next([]);
            }
        });
    }

    public async showSyncingErrors() {
        if (this.syncErrorJobs$.value.length > 0) {
            this.modalCtrl.show(ShowSyncingErrorsComponent, { syncingErrors: this.syncErrorJobs$.value });
        }
    }

    public logOut() {
        this.auth.signOut();
        this.router.navigate(['/login']);
    }

    public tabs$ = this.navState.selectedView$.pipe(
        map(selected => ([
            {
                label: 'Routing',
                selected: selected === 'map',
                icon: 'map-pin',
                handler: () => {
                    this.state.resetSelectedStopList();
                    this.navState.setSelectedView('map')
                }
            },
            {
                label: 'Assignment',
                selected: selected === 'assignments',
                icon: 'truck',
                handler: () => { 
                    this.state.resetSelectedStopList();
                    this.navState.setSelectedView('assignments')
                }
            },
            {
                label: 'Messages',
                selected: selected === 'messages',
                icon: 'message',
                handler: async () => {
                    const pendingMessagesStopList = await firstValueFrom(this.state.pendingMessagesStopList$);
                    this.state.removeAllVisibleStopLists();
                    this.state.setSelectedStopList(pendingMessagesStopList);
                    this.navState.setSelectedView('messages');
                }
            }
        ]))
    );

    public async createEmptyRoutes() {
        const date = await this.state.selectedDate$
            .pipe(first())
            .toPromise();
        await this.planning.createEmptyRoutes(date);
    }

    public async bellClickHandler() {
        //this.state
    }

    public async printBookReport() {
        this.printBookReportButtonPressedAndWaiting$.next(true);
        const date = await this.state.selectedDate$
            .pipe(first())
            .toPromise();

        let bookReportDataResuls = await this.planning.printBookReport(date);

        if (bookReportDataResuls.length == 0) {
            alert("There was an error processing the report, please try again.");
            return;
        }

        var reportHtml = "<html><head><title>Book Report: " + date + "</title><link rel='stylesheet' href='https://firebasestorage.googleapis.com/v0/b/streamlion.appspot.com/o/BookReportTable.css?alt=media&token=1e031fa7-36db-4a5f-9ba2-9676b44844e0'></head>";

        reportHtml += "<body><center>" + date + "</center>";
        reportHtml += "<table>";

        reportHtml += "<tr>";
        reportHtml += "<th>Order Id</th>";
        reportHtml += "<th>Time Window</th>";
        reportHtml += "<th>Stop</th>";
        reportHtml += "<th>Location</th>";
        reportHtml += "<th>Route</th>";
        reportHtml += "<th>Driver</th>";
        reportHtml += "<th>Vehicle</th>";
        reportHtml += "<th>Phone</th>";
        reportHtml += "<th>Phone 2</th>";
        reportHtml += "<th>Sales</th>";
        reportHtml += "</tr>";

        bookReportDataResuls.forEach(resultLine => {
            reportHtml += `<tr>`;
            reportHtml += `<td>${resultLine["Order#"]}</td>`;
            reportHtml += `<td>missing time window</td>`;
            reportHtml += `<td>missing stop</td>`;
            reportHtml += `<td>${resultLine["Location"]}</td>`;
            reportHtml += `<td>missing route</td>`;
            reportHtml += `<td>missing driver</td>`;
            reportHtml += `<td>missing vehicle</td>`;
            reportHtml += `<td>${resultLine["Phone"]}</td>`;
            reportHtml += `<td>${resultLine["Phone 2"]}</td>`;
            reportHtml += `<td>${resultLine["Sales person"]}</td>`;
            reportHtml += `</tr>`;
        });

        reportHtml += "</table>"
        reportHtml += "</body></html>";

        var reportPopup = window.open("", "_blank", "menubar=yes;width=900,height=600,scrollbars=1,resizable=1")

        reportPopup.document.open();
        reportPopup.document.write(reportHtml);
        reportPopup.document.close();

        this.printBookReportButtonPressedAndWaiting$.next(false);
    }

    public setComId(id) {
        this.env.setComId(id);
    }

    public creatingRoutesMessage$ = this.state.selectedDate$
        .pipe(
            switchMap(date => combineLatest(
                this.data.itineraryCreationInProg$(date),
                this.printBookReportButtonPressedAndWaiting$
            )),
            map(areUpdating => areUpdating.some(Boolean) ? `Creating routes...` : '')
        );

    public username():  string{
        return this.user?.displayName ?? this.user?.email ?? 'username';
    }
    public initials(){
        let user = this.username();
        user = user.substring(0, user.indexOf('@'));
        const first = user.charAt(0);
        const dotPosition = user.indexOf('.');
        const second = dotPosition > 0 && dotPosition < user.length-1 ? user.charAt(dotPosition+1) : '';
        return `${first}${second}`;
    }
}

